import * as React from 'react';
import styled from '@emotion/styled';

import Camera from '../assets/images/camera-circle.svg';
import CrossedEye from '../assets/images/eye-crossed.svg';
import Lock from '../assets/images/lock-circle.svg';
import Lock2 from '../assets/images/lock.svg';
import Save from '../assets/images/save.svg';
import Tick from '../assets/images/tick.svg';
import Tracking from '../assets/images/tracking-circle.svg';
import { EXTENSION_LINK } from '../config';
import {
  CameraContentItem,
  CheckListItem,
  FAQInfo,
  FeatureItem,
} from './interfaces';

const LinkStyled = styled.a`
  text-decoration: underline !important;
`;

export const featureItems: FeatureItem[] = [
  {
    icon: Camera,
    title: 'No Specialised Hardware',
    description: (
      <span>
        All you need is the Google Chrome browser,{' '}
        <a target="_blank" rel="noopener noreferrer" href={EXTENSION_LINK}>
          Vision Project Chrome Extension
        </a>{' '}
        and a webcam.
      </span>
    ),
  },
  {
    icon: Tracking,
    title: 'Eye-Tracking Technology',
    description: (
      <span>
        We use your webcam to detect your eyes and predict where you are looking
        on the screen. No images leave your device.
      </span>
    ),
  },
  {
    icon: Lock,
    title: 'No Account Required',
    description: (
      <span>
        Every session is anonymous. No personal information is captured or
        stored.
      </span>
    ),
  },
];

export const completionItems: FeatureItem[] = [
  {
    icon: CrossedEye,
    title: 'Eye Tracking Switched Off',
    description: (
      <span>
        We’ve disabled the camera and stopped any eye tracking activity on this
        page.
      </span>
    ),
  },
  {
    icon: Lock2,
    title: 'Close Study Tabs',
    description: (
      <span>
        If you have other tabs open from the study, close them now or refresh
        the page to stop the eye tracking.
      </span>
    ),
  },
];

export const completionItemSaveCode: FeatureItem = {
  icon: Save,
  title: 'Save Completion Code',
  description: (
    <span>
      Scroll down to access your task completion code. Submit this to your task
      provider to confirm you have completed the task.
    </span>
  ),
};

export const completionItemContactProvider: FeatureItem = {
  icon: Tick,
  title: 'Thanks For Participation',
  description: (
    <span>
      Please visit your panel provider website regarding your payment. For
      futher assistance please email support@vision-project.co
    </span>
  ),
};

export const checkListItems: CheckListItem[] = [
  {
    key: 'extension',
    title: 'Install the ',
    description: 'Return to this page after installation',
    link: {
      label: 'Vision Project Chrome Extension',
      url: EXTENSION_LINK,
    },
  },
  {
    key: 'webcam',
    title: 'Check webcam position',
    description: 'It should be positioned directly in front of you',
  },
  {
    key: 'monitors',
    title: 'No external monitors',
    description: 'Use your default monitor and disconnect all external ones',
  },
  {
    key: 'blockers',
    title: 'Switch off ad blockers for all sites',
    description: 'Ad blockers interfere with eye tracking performance',
  },
  {
    key: 'windows',
    title: 'Close every tab in your browser (except this one)',
    description: 'Alternatively, you can open this page in a fresh window',
  },
  {
    key: 'size',
    title: 'Maximise Browser Window',
    description:
      'For best results, open this study in a new window and maximise it.',
  },
];

export const cameraContent: { [id: string]: CameraContentItem } = {
  howItWorks: {
    title: 'How does eye tracking work?',
    description:
      'The webcam detects the movements of your eyes to estimate where you are looking on the screen. The prediction is improved through several eye tracking accuracy tests.',
    button: 'Turn on webcam',
  },
  setup: {
    title: 'Let’s set up your eye tracking',
    description:
      'Eye tracking is sensitive to harsh sunlight, strong shadows and awkward camera angles. Optimise your set up to ensure you can pass the accuracy checks.',
    button: 'Optimise My Set Up',
  },
};

export const FAQCategories: { [id: string]: string } = {
  about: 'About the Study',
  requirement: 'Minimum Device Requirements',
  payment: 'Payment',
  extension: 'The Chrome Extension',
  privacy: 'Data Collection & Privacy',
  tracking: 'In Task: Eye Tracking Set Up',
  browsing: 'In Task: Browsing Articles',
  completion: 'In Task: Completion Process',
  troubleshooting: 'Troubleshooting',
};

export const FAQContent: FAQInfo[] = [
  {
    category: 'about',
    title: 'What is this study for?',
    content: (
      <span>
        The Vision Project is one of the world’s leading eye tracking market
        research platforms. The data from this study is 100% anonymous and is
        used to better understand user behaviour and interaction on websites.
        Insights from this study help our partners (websites, brands, content
        creators, publishers) improve their products.
      </span>
    ),
  },
  {
    category: 'about',
    title: 'How do I access the study?',
    content: (
      <span>
        Participation in this study is by invitation only. A direct link to the
        study will be provided to you by the task provider when you accept the
        task. You will not be able to access it without this link.
      </span>
    ),
  },
  {
    category: 'about',
    title: 'What happens during the study?',
    content: (
      <span>
        Follow the setup instructions carefully to ensure you have the required
        hardware and set up requirements for the study. Make sure you have the
        Vision Project Chrome Extension installed and have completed each step
        before clicking the ‘Begin Study’ button.
        <br />
        <br />
        After agreeing to our privacy policy, you will be guided through the eye
        tracking set up process. This process can vary depending on your set up
        but is important to ensure the accuracy of the eye tracking.
        <br />
        <br />
        Once eye tracking has been set up, you will receive instructions for a
        browsing task. During the browsing task you will be asked to browse
        various websites and complete eye tracking accuracy tests. We will track
        your progress throughout the study and notify you when the task is
        complete. At the end of the study, you will receive a completion code.
        Provide this to your task provider to receive payment.
      </span>
    ),
  },
  {
    category: 'requirement',
    title:
      'What are the minimum device requirements to participate in the study?',
    content: (
      <span>
        The minimum device requirements for the Vision Project study are:
        <ul>
          <li>Webcam: 720p / 30fps (it can be a laptop or external webcam)</li>
          <li>Computer: A modern computer which is no older than two years.</li>
        </ul>
        We cannot guarantee your hardware will be compatible with our eye
        tracking technology. If you participate in the study with a device that
        does not meet minimum device requirements, you may not be able to pass
        the eye tracking set up and as a result, may not be able to complete the
        study.
      </span>
    ),
  },
  {
    category: 'payment',
    title: 'How do I receive payment?',
    content: (
      <span>
        At the end of the study, you will receive a completion code. Save this
        code and submit it to your task provider. All payments are handled
        directly by your task provider. Please contact them directly for any
        payment enquiries.
        <br />
        Please note that the Vision Project team will be unable to assist with
        any payment enquiries.
      </span>
    ),
  },
  {
    category: 'extension',
    title: 'What is a Chrome Extension?',
    content: (
      <span>
        Chrome Extensions are small software programs that change or enhance the
        functionality of your browser in some way. Similar to an app on your
        mobile, a user chooses to download and install a chrome extension,
        usually to fulfil a specific purpose or need. Extensions can be
        uninstalled anytime. You can browse the full range of extensions via the{' '}
        <LinkStyled
          target="_blank"
          rel="noopener noreferrer"
          href="https://chrome.google.com/webstore/category/extensions"
        >
          Chrome web store
        </LinkStyled>
        .
      </span>
    ),
  },
  {
    category: 'extension',
    title: 'Why do we need to install the Vision Project Chrome Extension?',
    content: (
      <span>
        Our studies require the use of eye tracking technology which we make
        available via the Vision Project chrome extension. To complete our
        studies, you must install the chrome extension and keep it enabled for
        the duration of the study.
        <br />
        <br />
        Once the study is complete you can disable or remove the chrome
        extension.
      </span>
    ),
  },
  {
    category: 'extension',
    title: 'How do I install the Chrome Extension?',
    content: (
      <span>
        To install the Vision Project chrome extension, you will need to be
        using the Google Chrome web browser. The extension is available via this{' '}
        <a target="_blank" rel="noopener noreferrer" href={EXTENSION_LINK}>
          link
        </a>{' '}
        in the Chrome Web Store.
        <br />
        <br />
        Once on this page, follow the instructions to install the Chrome
        Extension.
      </span>
    ),
  },
  {
    category: 'extension',
    title: 'How do I disable the Chrome Extension?',
    content: (
      <span>
        <b>
          Note: if you disable the chrome extension during a study then your
          progress will be lost and you will not be able to complete the study.
        </b>
        <br />
        <br />
        To disable the chrome extension, right click the Vision Project icon,
        located in the top right corner of your Google Chrome browser. In this
        menu you will find an option to ‘Disable Vision Project’.
      </span>
    ),
  },
  {
    category: 'extension',
    title: 'How do I remove the Chrome Extension?',
    content: (
      <span>
        <b>
          Note: if you remove the chrome extension during a study then your
          progress will be lost and you will not be able to complete it.
        </b>
        <br />
        <br />
        To remove or uninstall the chrome extension, right click the Vision
        Project icon, located in the top right corner of your Google Chrome
        browser. In this menu you will find an option to ‘Remove from Chrome’
      </span>
    ),
  },
  {
    category: 'privacy',
    title: 'What are my results going to be used for?',
    content: (
      <span>
        Your results are used to help our partners (websites, brands, content
        creators, publishers) improve their products.
        <br />
        <br />
        The data that we collect includes eye gaze data and browsing behaviour
        data. All data collected is 100% anonymous and no images leave your
        device.
      </span>
    ),
  },
  {
    category: 'tracking',
    title: 'I’m having trouble setting up my eye tracking, what shall I do?',
    content: (
      <span>
        Detailed instructions on how to set up eye tracking can be found within
        the study. If you haven’t read this already then please ensure you do so
        before proceeding any further.
        <br />
        <br />
        If you have already read these instructions and are still having issues
        then please refer to the FAQs further below.
      </span>
    ),
  },
  {
    category: 'tracking',
    title: 'I’m stuck on the EYE TRACKING BLOCKED page',
    content: (
      <span>
        The most likely reason for this is that either your browser, another
        application or another extension is restricting our tech from running
        properly.
        <br />
        <br />
        Please check to ensure that within your Google Chrome privacy and
        security settings you have chosen one of the following for Cookies and
        other site data; ‘Allow all cookies’ or ‘Block third party cookies in
        Incognito’. These settings can be found by typing chrome://settings into
        your browser and navigating to the Privacy and security tab.
        <br />
        <br />
        Alternatively, if you have an ad blocker or cookie blocker installed
        then please ensure it is turned off.
        <br />
        <br />
        If these steps don’t resolve your issue then please contact us using the
        details included further below.
      </span>
    ),
  },
  {
    category: 'tracking',
    title: 'I can’t pass the accuracy check, what should I do?',
    content: (
      <span>
        Eye tracking is tricky business and there are a number of reasons why
        you might not be able to pass. We suggest you start by ensuring you have
        carefully followed the optimisation instructions included in the study.
        <br />
        <br />
        In the case you are still stuck then you can try the following:
        <ul>
          <li>If you are wearing glasses, try removing them.</li>
          <li>
            If you have access to a different set of devices (which meet our
            minimum device requirements), try using these.
          </li>
          <li>
            Bright, even lighting across your face (and especially your eyes)
            helps the camera detect your eye movements. Imagine the lighting
            conditions used in a lab. Avoid harsh sunlight as the reflection can
            interfere with the quality of the eye tracking.
          </li>
          <li>
            Eye tracking accuracy relies heavily on the camera setup process.
            When looking at each dot, be mindful to focus only on the dot and
            nothing else. It’s natural to constantly scan our environment
            peripherally, and we may not even be aware we are doing it. It takes
            significant focus to slow down and look at one spot for a set amount
            of time.
          </li>
          <li>
            Keep your head still during the set up and throughout the study.
          </li>
          <li>
            If you have anything which might be covering your face, try changing
            this.
          </li>
        </ul>
        <br />
        <br />
        Unfortunately we can’t guarantee that our eye tracking technology will
        work with everyone. If you have tried all of the above but still can’t
        get it to work then please contact us using the details included further
        below.
      </span>
    ),
  },
  {
    category: 'tracking',
    title: 'I can’t get my webcam to connect?',
    content: (
      <span>
        If no image is being displayed in the camera preview window within the
        study pages then you can try the following.
        <br />
        <br />
        Click the camera icon on the far right of the navigation bar and ensure
        that you have allowed camera access. Within the pop-up menu which
        appears, the camera drop down box should be populated with the webcam
        which you are trying to use. If this is not the case then select the
        correct camera.
        <br />
      </span>
    ),
  },
  {
    category: 'tracking',
    title:
      'I blocked the Vision Project from accessing my camera, what should I do?',
    content: (
      <span>
        If you block or revoke access to the camera during the study you will be
        redirected to an error page with instructions on how to resolve it. If
        for some reason this page isn’t displayed then please try using the
        resolution included in the previous question.
      </span>
    ),
  },
  {
    category: 'tracking',
    title: 'How does the eye tracking technology work?',
    content: (
      <span>
        We use your webcam to capture images of your face. From these images we
        extract an image of each of your eyes. We then pass these images through
        a mathematical model which makes a prediction of where you are looking
        on the screen. All image processing takes place on your own device and
        the only thing we receive are the coordinates of where on the screen you
        were looking.
      </span>
    ),
  },
  {
    category: 'browsing',
    title: 'What sites am I allowed to visit?',
    content: (
      <span>
        To complete the task you must only browse websites which are specified
        in the Site Directory. Navigating to any website outside of this list
        will not count towards your task progress and will result in the study
        being paused.
      </span>
    ),
  },
  {
    category: 'browsing',
    title: 'Can I open multiple tabs?',
    content: (
      <span>
        We encourage you to keep only two tabs open while completing the task.
        One of these open tabs should contain the site directory and the other
        you should use for browsing.
      </span>
    ),
  },
  {
    category: 'browsing',
    title: 'Can I browse multiple websites?',
    content: (
      <span>
        Yes, you can browse any website which is specified within the Site
        Directory and are free to navigate between them throughout the study.
      </span>
    ),
  },
  {
    category: 'browsing',
    title:
      'What happens if I try to visit a website that is not specified within the study?',
    content: (
      <span>
        You will be redirected to a page which notifies you are leaving the
        study.
        <br />
        <br />
        If you wish to proceed to the website outside of the study, click the
        ‘continue anyway’ button. Doing this will cause the study to stop and
        result in a notification being displayed on any new page indicating that
        you have left the study. Whilst browsing a page that is not within the
        Site Directory, the eye tracking will be switched off and the study will
        not progress.
        <br />
        <br />
        If you wish to instead continue the study, select a new site from the
        Site Directory.
      </span>
    ),
  },
  {
    category: 'completion',
    title: 'I’ve completed the study, what should I do?',
    content: (
      <span>
        On completion of the study you should be provided a unique completion
        code which you can submit to your task provider for payment.
        <br />
        <br />
        You may also disable or remove the Vision Project chrome extension.
      </span>
    ),
  },
  {
    category: 'completion',
    title: 'I didn’t receive a completion code',
    content: (
      <span>
        The completion code page will only be displayed after the study is
        complete. This is indicated by the progress bar reaching 100%.
        <br />
        <br />
        If for some reason you did not receive a completion code then please
        contact us using the details included further below.
      </span>
    ),
  },
  {
    category: 'troubleshooting',
    title: 'My task froze and I wasn’t able to complete it, what should I do?',
    content: (
      <span>
        If your task froze or something went wrong while completing the study
        you can try restarting Google Chrome and navigating back to the link you
        were provided by your Task Provider. If this doesn’t fix the issue then
        please contact us using the details included further below.
      </span>
    ),
  },
  {
    category: 'troubleshooting',
    title: 'I’m still stuck, is there someone I can contact for help?',
    content: (
      <span>
        I’m still stuck, is there someone I can contact for help? If you need
        further assistance please reach out to us on:{' '}
        <a href="mailto:support@vision-project.co">support@vision-project.co</a>
        <br />
        <br />
        In the email please try and describe the issue you are facing as best as
        possible by including the following details:
        <ul>
          <li>
            The name of the task provider where you accepted this Vision Project
            study.
          </li>
          <li>The make/model of your computer and webcam.</li>
          <li>A description of the issue you are facing.</li>
        </ul>
        <b>
          Please note our support desk is only available: 9am - 5pm (AEST), Mon
          - Fri.
        </b>
      </span>
    ),
  },
  {
    category: 'troubleshooting',
    title: 'I’m stuck on the AD BLOCKER DETECTED page',
    content: (
      <span>
        If you have been redirected to this page then it means we have detected
        that an ad blocker is running. To complete the study you must turn off
        any ad blocker which you may be using.
        <br />
        Ad blockers can be found in a number of places, however the most common
        are:
        <ul>
          <li>
            As a chrome extension within the Google Chrome browser: to check if
            you have an ad blocker chrome extension installed, navigate to
            chrome://extensions by typing it in the URL bar and look through the
            list of installed extensions. If you locate an ad blocker chrome
            extension then either pause or disable it while you complete the
            Vision Project study.
            <br />
          </li>
          <li>
            Within your anti-virus software: search your machine for any
            antivirus software which may be installed. Once located, open the
            anti-virus software and ensure that any ad blocking features are
            paused or disabled.
          </li>
        </ul>
        <br />
        Please ensure that your ad blocker is either completely paused or
        disabled while completing the Vision Project study, and is not just set
        to ‘Allow from desktop.vision-project.co’.
      </span>
    ),
  },
];
