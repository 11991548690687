import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { Background } from '../../../../shared/components/background';
import { Button } from '../../../../shared/components/button';
import { Content } from '../../../../shared/components/content';
import { Icon } from '../../../../shared/components/icon';
import { Section } from '../../../../shared/components/section';
import { Spacing } from '../../../../shared/components/spacing';
import { HeaderSmall } from '../../../../shared/composites/headerSmall';
import CameraBlock from '../../assets/images/camera-block.jpg';
import VideoCam from '../../assets/images/videocam.svg';
import { getChromeExtId } from '../../core/utils';

const FrameContainer = styled.div`
  position: relative;
  width: 325px;
  height: 245px;
  margin: auto;
  img {
    width: 100%;
  }
`;

const ContentStyled = styled.div`
  max-width: 500px;
  margin: auto;
  ul {
    list-style: decimal;
    li {
      padding-top: 5px;
    }
  }
`;

const IconStyled = styled.div`
  display: inline;
  vertical-align: -6px;
`;

export const Permissions: React.FunctionComponent = () => {
  const history = useHistory();
  const [inject, setInject] = useState<boolean>(false);
  let extensionFrame;

  const onSubmit = (): void => {
    history.push('/camera');
  };

  useEffect(() => {
    setInject(true);
  }, []);

  if (inject) {
    extensionFrame = (
      <iframe
        src={`chrome-extension://${getChromeExtId()}/ui/eye-tracking.html?preview=true`}
        allow="camera"
        style={{ display: 'none' }}
      />
    );
  }
  return (
    <Section>
      <Background />
      <Content>
        <Spacing size={60} unit={'px'} />
        <HeaderSmall label="camera access blocked" />
        <Spacing size={40} unit={'px'} />
        <FrameContainer>
          <img src={CameraBlock} alt="Vision Mode" />
        </FrameContainer>
        <ContentStyled>
          <Spacing size={20} unit={'px'} />
          <h4>Looks like camera access is disabled</h4>
          <Spacing size={20} unit={'px'} />
          <span>
            <ul>
              <li>
                Click on the{'  '}
                <IconStyled>
                  <Icon height={24} width={24} color="#fff">
                    <VideoCam />
                  </Icon>
                </IconStyled>
                {'  '}
                icon in the URL bar
              </li>
              <li>Allow Vision Project to access your camera</li>
              <li>Click the button below to go back to the study</li>
            </ul>
          </span>
          <Spacing size={40} unit={'px'} />
          <div className="centered">
            <Button
              clickHandler={onSubmit}
              label="Back to camera setup"
              disabled={false}
            />
          </div>
        </ContentStyled>
      </Content>
      {extensionFrame}
    </Section>
  );
};
