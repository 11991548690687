import React from 'react';
import styled from '@emotion/styled';
import { ErrorTemplate } from '../../composites/error/errorTemplate';

import YouTubeSignOut from '../../assets/images/youtube-signout.gif';
import { YOUTUBE_URL } from '../../../../shared/config';

const StyledDiv = styled.div`
  margin-bottom: 2vh;
`;

const StyledImg = styled.img`
  width: 30vh;
`;

export const YouTubePremium: React.FunctionComponent = () => {
  return (
    <ErrorTemplate
      headerSmall={'User Account Error'}
      title={'Youtube premium user'}
      content={
        <React.Fragment>
          <StyledDiv>
            We detected that you are a YouTube premium user. Please return the
            the YouTube hompage and log out from your premium account to
            complete the task
          </StyledDiv>
          <StyledDiv>
            <StyledImg src={YouTubeSignOut} />
          </StyledDiv>
        </React.Fragment>
      }
      button={{
        label: 'Go To YouTube Homepage',
        disabled: false,
        clickHandler: (): void => {
          window.location.href = YOUTUBE_URL;
        },
      }}
    />
  );
};
