/* eslint-disable */
import { action, computed, observable } from 'mobx';
import { fetchRemoteConfig } from '../services/firebaseService';
import Cookies from 'universal-cookie';
import { LogType } from '../../../shared/types/enums';
import { logger } from '../core/logger';
import {
  Message,
  onMessageActiveData,
  onMessageCompletionData,
  onMessageProgressData,
  onMessageStudyDetailsRequest,
  onMessageCameraPermissions,
} from '../core/message';
import { getChromeExtId } from '../core/utils';

export class ExtensionStore {
  private chromePort: chrome.runtime.Port;

  @observable
  private _progress = 0;

  @observable
  private _duration = 0;

  @observable
  private _code = '';

  @observable
  private _connecting = true;

  @observable
  private _connected = false;

  @observable
  private _active = false;

  @observable
  private _cameraPermissions: string | null = null; // empty state before being updated by the POST request

  @observable
  private _validationRegionCfg: string | undefined = undefined;

  @computed
  get validationRegionCfg(): string | undefined {
    return this._validationRegionCfg;
  }

  connectToExtension(): void {
    const getExtensionConnection: NodeJS.Timeout = global.setInterval(() => {
      const extensionId: string | null = getChromeExtId();
      if (extensionId) {
        clearInterval(getExtensionConnection);
        try {
          this.chromePort = chrome.runtime.connect(extensionId);
          logger.log(
            `establishing connection pipeline with extension \n   id : ${extensionId}`
          );
          //Create a listener to receive messages sent by Vision Project chrome extension
          this._connected = true;
          this.chromePort.onMessage.addListener(this.onExtentionMethod);
          this.chromePort.onDisconnect.addListener(this.connectToExtension);
          this.requestActivated();
        } catch {
          window.location.reload();
        }
      } else {
        logger.warn('failed to detect extension');
        this._connected = false;
        global.clearInterval(getExtensionConnection);
      }
    }, 1000);
  }

  private onExtentionMethod = (message: Message): void => {
    // Call onMessageProgressData in core/message.ts, the method acts as a type check and will call the handler
    // only if the message format match ProgressData
    onMessageProgressData(message, (data) => {
      //Call store set action, it will trigger re-render on all observer controllers
      this.setProgress(data.progress);
      this.setDuration(data.duration);
    });
    onMessageStudyDetailsRequest(message, () => {
      const cookies = new Cookies();
      this.chromePort.postMessage({
        pxyz: {
          data: cookies['vision-study'],
        },
      });
    });
    onMessageCompletionData(message, (data) => {
      if (data.code != '') {
        logger.log(
          `received completion code from extension \n   code : ${data.code}`
        );
        this.setCode(data.code);
      }
    });
    onMessageActiveData(message, (data) => {
      this._connecting = false;
      this._active = data.isActive;
      logger.log(
        `received extension active status ${this._active} & connnecting ${this._connecting}`
      );
    });
    onMessageCameraPermissions(message, (data) => {
      if (data.cameraPermissions) {
        logger.log('camera permission recieved', {
          cameraPermissions: data.cameraPermissions,
          logType: LogType.CAMERA_PERMISSIONS,
        });
        this.setCameraPermissions(data.cameraPermissions);
      }
    });
  };

  @computed
  get progress(): number {
    return this._progress;
  }

  @action
  setProgress(value: number): void {
    this._progress = value;
  }

  @computed
  get duration(): number {
    return this._duration;
  }

  @action
  setDuration(value: number): void {
    this._duration = value;
  }

  @action
  async fetchValRegionCfg(): Promise<void> {
    this._validationRegionCfg = await fetchRemoteConfig();
  }

  @action
  sendDetails(): void {
    if (this.chromePort) {
      const cookies = new Cookies(document.cookie);
      logger.log(`sending study details to extension`);
      this.chromePort.postMessage({
        pxyz: {
          data: {
            duration: cookies.get('vision-study')['duration'],
            feedId: cookies.get('vision-study')['feedId'],
            feedUrlPath: cookies.get('vision-study')['feedUrlPath'],
            adCampaignId: cookies.get('vision-study')['adCampaignId'],
            userId: cookies.get('vision-study')['userId'],
            type: cookies.get('vision-study')['type'],
            platformId: cookies.get('vision-study')['platformId'],
            vpDebugMode: cookies.get('vision-study')['vpDebugMode'],
            vpQaMode: cookies.get('vision-study')['vpQaMode'],
            validationRegionCfg: this._validationRegionCfg,
          },
        },
      });
    }
  }

  @computed
  get code(): string {
    return this._code;
  }

  @action
  setCode(value: string): void {
    this._code = value;
  }

  @computed
  get connected(): boolean {
    return this._connected;
  }

  @action
  setConnected(value: boolean): void {
    this._connected = value;
  }

  @computed
  get active(): boolean {
    return this._active;
  }

  @action
  setActive(value: boolean): void {
    this._active = value;
  }

  @computed
  get connecting(): boolean {
    return this._connecting;
  }

  @action
  setConnecting(value: boolean): void {
    this._connecting = value;
  }

  @action requestCameraPermissions(): void {
    if (this.chromePort) {
      logger.log(`sending request for camera permissions`);
      this.chromePort.postMessage({
        pxyz: {
          data: {
            request: 'cameraPermissions',
          },
        },
      });
    }
  }

  @action requestCode(): void {
    if (this.chromePort) {
      logger.log(`sending request for completion code`);
      this.chromePort.postMessage({
        pxyz: {
          data: {
            request: 'code',
          },
        },
      });
    }
  }

  @action requestActivated(): void {
    if (this.chromePort) {
      logger.log(`sending request for active state`);
      this.chromePort.postMessage({
        pxyz: {
          data: {
            request: 'active',
          },
        },
      });
    }
  }

  @action openNewTab(url: string): void {
    if (this.chromePort) {
      logger.log(`sending request for opening new tab`);
      this.chromePort.postMessage({
        pxyz: {
          data: {
            request: 'newTab',
            url,
          },
        },
      });
    }
  }

  @action
  setCameraPermissions(value: string): void {
    this._cameraPermissions = value;
  }

  @computed
  get cameraPermissions(): string | null {
    return this._cameraPermissions;
  }
}
