import styled from '@emotion/styled';
import { Typeahead } from '@gforge/react-typeahead-ts';
import { TweenLite } from 'gsap';
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button } from '../../../../shared/components/button';
import { Grid } from '../../../../shared/components/grid';
import { Icon } from '../../../../shared/components/icon';
import { Spacing } from '../../../../shared/components/spacing';
import { Logger } from '../../../../shared/logger';
import ArrowLeft from '../../assets/images/arrow-left.svg';
import ArrowRight from '../../assets/images/arrow-right.svg';
import Cross from '../../assets/images/cross.svg';
import Search from '../../assets/images/search.svg';
import Tab from '../../assets/images/tab.png';
import { VP_API_URL, SITE_PAGE_SIZE } from '../../config';
import { usePrevious } from '../../core/hooks';
import { feedTypeContent } from '../../core/feedType';
import { useVPCookieStore } from '../../stores/store';

gsap.registerPlugin(ScrollToPlugin);

const SiteDirectoryContainer = styled.div`
  position: relative;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: stretch;
`;

const Header = styled.div`
  position: relative;
`;

const Title = styled.div`
  margin: 0;
  font-family: 'Avenir', helvetica, arial, sans-serif;
  font-weight: normal;
  letter-spacing: 8px;
  font-size: 1.8em;
  text-align: center;
`;

const SearchStyled = styled.div`
  width: 250px;
  padding: 5px 0;
  padding-left: 35px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border: 1px solid #fff;
  border-radius: 99px;
  z-index: 1;
  input {
    font-family: 'Roboto', helvetica, arial, sans-serif;
    font-size: 1em;
    background-color: transparent;
    height: 25px;
    width: 185px;
    border: 0;
    outline: 0;
    color: #fff;
  }
  .typeahead-selector {
    display: none;
  }
`;

const SearchIcon = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  fill: #fff;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
`;

const SearchClear = styled.a`
  position: absolute;
  width: 24px;
  height: 24px;
  fill: #fff;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
`;

const Pages = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const Page = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  .grid-item {
    margin-right: 5%;
    &:nth-of-type(3n) {
      margin-right: 0;
    }
  }
`;

const SiteItemStyled = styled.div`
  position: relative;
  padding: 20px 0;
  padding-left: 63px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
`;

const SiteItemLogo = styled.a`
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  position: absolute;
  width: 48px;
  height: 48px;
  background-color: #fff;
  border-radius: 99px;
`;

const SiteItemAvatar = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-transform: capitalize;
  color: #b8b8b8;
  font-size: 1.6em;
`;

const SiteItemTitle = styled.a`
  display: block;
  text-transform: capitalize;
  font-size: 1.2em;
  margin-bottom: 5px;
  text-decoration: none;
`;

const SiteItemLink = styled.a`
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
`;

const Pagination = styled.div`
  text-align: right;
  opacity: 0;
  span {
    vertical-align: 20px;
  }
`;

const PaginationArrow = styled.a`
  cursor: pointer;
  display: inline-block;
  width: 48px;
  height: 48px;
  fill: #fff;
`;

const NotificationPage = styled.div`
  z-index: 2;
  max-width: 400px;
  position: absolute;
  text-align: center;
  visibility: hidden;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
`;

const ModalPopup = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 5;
  top: 0;
  left: 0;
`;

const PopupContainer = styled.div`
  position: absolute;
  width: 25vw;
  height: 35vh;
  min-height: 330px;
  z-index: 6;
  background-color: #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  b,
  span {
    color: #000;
  }
  img {
    width: 85%;
  }
`;

const LoaderContainer = styled.div`
  margin: auto;
`;

interface SiteInfo {
  logo: string;
  name: string;
  url: string;
}

interface SiteItemProps {
  info: SiteInfo;
  clickHandler?(e: React.MouseEvent, site: string): void;
  hrefTarget: string;
}

const SiteItem: React.FunctionComponent<SiteItemProps> = (
  props: SiteItemProps
) => {
  const { info, clickHandler, hrefTarget } = props;
  const onClick = (e: React.MouseEvent): void => {
    if (clickHandler) {
      clickHandler(e, info.url);
    }
  };
  return (
    <SiteItemStyled>
      <SiteItemLogo
        href={info.url}
        onClick={onClick}
        target={hrefTarget}
        rel="noopener noreferrer"
      >
        <SiteItemAvatar>{info.name.charAt(0)}</SiteItemAvatar>
      </SiteItemLogo>
      <SiteItemTitle
        href={info.url}
        onClick={onClick}
        target={hrefTarget}
        rel="noopener noreferrer"
      >
        {info.name}
      </SiteItemTitle>
      <SiteItemLink
        href={info.url}
        onClick={onClick}
        target={hrefTarget}
        rel="noopener noreferrer"
      >
        {info.url.replace(/(?:https?:\/\/)/i, '')}
      </SiteItemLink>
    </SiteItemStyled>
  );
};

interface SiteDirectoryProps {
  openLinkOnNewTab?: boolean;
}
const defaultProps: SiteDirectoryProps = {
  openLinkOnNewTab: true,
};

export const SiteDirectory: React.FunctionComponent<SiteDirectoryProps> = observer(
  (props: SiteDirectoryProps) => {
    let pagesElement: HTMLDivElement;
    let paginationElement: HTMLDivElement;
    let arrowRightElement: HTMLAnchorElement;
    let arrowLeftElement: HTMLAnchorElement;
    let searchElement: HTMLDivElement;
    let notificationElement: HTMLDivElement;
    let popup;
    const searchInput = React.useRef<HTMLInputElement | undefined>();
    const [pageHeight, setPageHeight] = useState<number>(0);
    const [originalFeed, setOriginalFeed] = useState<SiteInfo[]>([]);
    const [list, setList] = useState<SiteInfo[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [searchOptions, setSearchOptions] = useState<string[]>([]);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [sitePopup, setSitePopup] = useState<string>('');
    const [hasShownPopup, setHasShownPopup] = useState<boolean>(false);
    const previousPage = usePrevious({ currentPage, setCurrentPage });
    const vpCookieStore = useVPCookieStore();

    const { openLinkOnNewTab } = props;
    const hrefTarget = openLinkOnNewTab ? '_blank' : '_self';

    const { siteDirectoryContent } = feedTypeContent(vpCookieStore.feedType);

    // we only want to display the pop-up for 'blank' targets
    const shouldShowPopUp = openLinkOnNewTab ? true : false;

    const shuffleFeed = (feed: SiteInfo[]): SiteInfo[] => {
      const shuffle: SiteInfo[] = feed;
      for (let i = shuffle.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i
        [shuffle[i], shuffle[j]] = [shuffle[j], shuffle[i]];
      }
      return shuffle;
    };

    const onPreviousPage = (): void => {
      if (currentPage != 0) {
        setCurrentPage(currentPage - 1);
      }
    };

    const onNextPage = (): void => {
      const pagesCount = document.querySelectorAll('.page').length - 1;
      if (currentPage < pagesCount) {
        setCurrentPage(currentPage + 1);
        gsap.set(window, { scrollTo: '#site-directory' });
      }
    };

    const onSearchChange = (): void => {
      const buttonElements: HTMLButtonElement[] = [].slice.call(
        searchElement.querySelectorAll('button')
      );
      if (buttonElements.length > 0) {
        const options: string[] = buttonElements.map(
          (item: HTMLButtonElement) => item.innerHTML.toLocaleLowerCase()
        );
        setList(
          originalFeed.filter((item) =>
            options.includes(item.url.toLowerCase())
          ) || originalFeed
        );
      } else {
        setList(originalFeed);
      }
      setCurrentPage(0);
    };

    const onSearchClear = (): void => {
      if (searchInput.current) {
        searchInput.current.value = '';
      }
      setList(originalFeed);
      setCurrentPage(0);
    };

    const onSiteClick = (e: React.MouseEvent, site: string): void => {
      if (!hasShownPopup && shouldShowPopUp) {
        setSitePopup(site);
        setShowPopup(true);
        e.preventDefault();
      }
    };

    const onPopupSubmit = (hrefTarget: string): void => {
      window.open(sitePopup, hrefTarget);
      setHasShownPopup(true);
    };

    useEffect(() => {
      const encodedURI = encodeURI(
        `${VP_API_URL}/siteDirectory/${vpCookieStore.feedUrlPath}/` +
          vpCookieStore.feedId
      );
      const MAX_ITEMS = 50;

      fetch(encodedURI)
        .then((data) => data.json())
        .then((feed: SiteInfo[]) => {
          let shuffled: SiteInfo[] = shuffleFeed(feed);
          if (shuffled.length > MAX_ITEMS) {
            shuffled = shuffled.slice(0, MAX_ITEMS);
          }

          setSearchOptions(shuffled.map((item) => item.url));
          setOriginalFeed(shuffled);
          setList(shuffled);
        })
        .catch((error) => {
          Logger.warn(`feed load failed \n   error : ${error}`);
          return null;
        });
    }, []);

    useEffect(() => {
      if (pagesElement) {
        const pages: HTMLDivElement[] = [].slice.call(
          pagesElement.querySelectorAll(':scope > .page')
        );
        if (pageHeight == 0) {
          setPageHeight(pages[0].clientHeight);
        }
        TweenLite.set(pagesElement, { height: pageHeight });
        TweenLite.set(pages[0], { visibility: 'visible' });
        TweenLite.to(pages[0], 0.5, { opacity: 1 });
        if (paginationElement && pages.length > 1) {
          TweenLite.to(paginationElement, 0.5, { opacity: 1 });
          TweenLite.to(arrowLeftElement, 0.5, {
            opacity: 0.5,
          });
        } else {
          TweenLite.to(paginationElement, 0.5, { opacity: 0 });
        }
      }
    }, [list, pageHeight]);

    useEffect(() => {
      if (pagesElement) {
        const pages: HTMLDivElement[] = [].slice.call(
          pagesElement.querySelectorAll(':scope > .page')
        );
        const previous: number = previousPage?.currentPage || 0;
        TweenLite.to(pages[previous], 0.5, {
          opacity: 0,
          onComplete: () => {
            TweenLite.set(pages[previous], { visibility: 'hidden' });
            TweenLite.set(pages[currentPage], { visibility: 'visible' });
            TweenLite.to(pages[currentPage], 0.5, { opacity: 1 });
            if (pages[currentPage].querySelectorAll('.grid-item').length < 36) {
              TweenLite.set(notificationElement, {
                visibility: 'visible',
                top: pages[currentPage].clientHeight + 100,
              });
              TweenLite.to(notificationElement, 0.3, {
                opacity: 1,
              });
            } else {
              TweenLite.set(notificationElement, {
                visibility: 'hidden',
                opacity: 0,
              });
            }
            if (currentPage === 0) {
              TweenLite.to(arrowLeftElement, 0.5, {
                opacity: 0.5,
              });
            }
            if (currentPage === 1) {
              TweenLite.to(arrowLeftElement, 0.5, {
                opacity: 1,
              });
            }
            if (currentPage === pages.length - 1) {
              TweenLite.to(arrowRightElement, 0.5, {
                opacity: 0.5,
              });
            }
            if (currentPage === pages.length - 2) {
              TweenLite.to(arrowRightElement, 0.5, {
                opacity: 1,
              });
            }
          },
        });
      }
    }, [currentPage]);

    if (list.length === 0) {
      return (
        <LoaderContainer>
          <h3>Loading ...</h3>
        </LoaderContainer>
      );
    }

    const pages: JSX.Element[] = [];
    let i, j;
    const chunk = SITE_PAGE_SIZE;

    for (i = 0, j = list.length; i < j; i += chunk) {
      const items: JSX.Element[] = list
        .slice(i, i + chunk)
        .map((item, index) => (
          <Grid
            item
            size={30}
            key={`site-directory-${item.name.toLowerCase()}-${index}`}
            className="grid-item"
          >
            <SiteItem
              info={item}
              clickHandler={onSiteClick}
              hrefTarget={hrefTarget}
            />
          </Grid>
        ));
      pages.push(
        <Page className="page" key={`site-page-${i}`}>
          <Grid container justifyContent="flex-start">
            {items}
          </Grid>
        </Page>
      );
    }
    if (showPopup && !hasShownPopup) {
      popup = (
        <ModalPopup>
          <PopupContainer>
            <div className="centered">
              <img src={Tab} />
              <Spacing size={30} unit="px" />
              <b>{siteDirectoryContent.modalPopup.boldText}</b>
              <Spacing size={20} unit="px" />
              <span>{siteDirectoryContent.modalPopup.spanText}</span>
              <Spacing size={30} unit="px" />
              <Button
                label="Got It"
                disabled={false}
                invert
                clickHandler={(): void => onPopupSubmit(hrefTarget)}
              />
            </div>
          </PopupContainer>
        </ModalPopup>
      );
    }

    return (
      <SiteDirectoryContainer>
        {popup}
        <Spacing size={50} unit="px" />
        <div id="site-directory" />
        <Header>
          <Title>{siteDirectoryContent.headerTitle}</Title>
          <SearchStyled
            ref={(node): HTMLDivElement =>
              (searchElement = node as HTMLDivElement)
            }
          >
            <SearchIcon>
              <Icon width={24} height={24}>
                <Search />
              </Icon>
            </SearchIcon>
            <Typeahead
              options={searchOptions}
              onKeyUp={onSearchChange}
              innerRef={searchInput}
            />
            <SearchClear onClick={onSearchClear}>
              <Icon width={24} height={24}>
                <Cross />
              </Icon>
            </SearchClear>
          </SearchStyled>
        </Header>
        <Spacing size={40} unit="px" />
        <Pages
          ref={(node): HTMLDivElement =>
            (pagesElement = node as HTMLDivElement)
          }
        >
          {pages}
          <NotificationPage
            ref={(node): HTMLDivElement =>
              (notificationElement = node as HTMLDivElement)
            }
          >
            <p>{"You've reach the end of the site list"}</p>
            <Spacing size={30} unit="px" />
            <Button
              label="Return to start of the list"
              disabled={false}
              clickHandler={(): void => {
                setCurrentPage(0);
              }}
            />
          </NotificationPage>
        </Pages>
        <Spacing size={40} unit="px" />
        <Pagination
          ref={(node): HTMLDivElement =>
            (paginationElement = node as HTMLDivElement)
          }
        >
          <PaginationArrow
            ref={(node): HTMLAnchorElement =>
              (arrowLeftElement = node as HTMLAnchorElement)
            }
            onClick={onPreviousPage}
          >
            <Icon width={48} height={48}>
              <ArrowLeft />
            </Icon>
          </PaginationArrow>
          <span>More Sites</span>
          <PaginationArrow
            ref={(node): HTMLAnchorElement =>
              (arrowRightElement = node as HTMLAnchorElement)
            }
            onClick={onNextPage}
          >
            <Icon width={48} height={48}>
              <ArrowRight />
            </Icon>
          </PaginationArrow>
        </Pagination>
      </SiteDirectoryContainer>
    );
  }
);

SiteDirectory.defaultProps = defaultProps;
