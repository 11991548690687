import styled from '@emotion/styled';
import * as React from 'react';

const BackgroundStyle = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  background-image: linear-gradient(#2f1c3a, #242424);
  background-color: #242424;
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
  z-index: -1;
`;

export const Background: React.FunctionComponent = () => {
  return <BackgroundStyle />;
};
