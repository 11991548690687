import { RouteItem } from './interfaces';

export const getChromeExtId = (): string | null => {
  const el = document.getElementById('pxyz-ext');
  const pxyzext = el?.dataset.pxyzext;
  return pxyzext || null;
};

export const getWheelEvent = (): string => {
  return 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
};

export const headRequest = (url: string): Promise<boolean> => {
  return new Promise(function (fullfill, reject) {
    const xhr = new XMLHttpRequest();
    xhr.open('HEAD', url, true);
    xhr.onreadystatechange = function (): void {
      if (this.readyState == 4) {
        if (this.status == 200) {
          fullfill(true);
        } else {
          reject(false);
        }
      }
    };
    xhr.send();
  });
};

const durationFactor1 = 10000;
const durationFactor2 = 0.5643;

export const setDurationParam = (value: number): string => {
  const temp = (value + value * durationFactor2) * durationFactor1;
  let result = temp.toString();
  result =
    result.substr(0, 3) + '-' + temp.toString().substr(3, result.length - 1);
  return result;
};

export const getDurationParam = (value: string): number => {
  const temp = value.replace(/-/gi, '');
  let result = parseInt(temp);
  result = result / (1 + durationFactor2);
  return result / durationFactor1;
};

export const isUnauthorized = (authorised: boolean | undefined): boolean => {
  return (authorised || false) === false;
};

export const needsPrivacyRedirect = (
  privacyAccepted: boolean,
  routeItem: RouteItem
): boolean => {
  return !privacyAccepted && routeItem.privacyCheck === true;
};
