import { CINT_REDIRECT, VP_API_URL } from '../../config';

const url = VP_API_URL + '/conversionUrl/cint/completeStudy';

const createBody = (id: string, feedId: string): string => {
  const data = { extId: id, feedId: feedId, status: 5 };
  return JSON.stringify(data);
};

interface CompletionStudyResponse {
  success: boolean;
  redirectUrl?: string;
}
//sending POST request to VP API to trigger study completion for the panelist
export const completeStudy = async (
  id: string,
  feedId: string
): Promise<CompletionStudyResponse> => {
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: createBody(id, feedId),
  });
  if (!response.ok) {
    return {
      success: false,
    };
  }
  return {
    success: true,
    redirectUrl: `${CINT_REDIRECT}${id}`,
  };
};
