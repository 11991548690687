import { VisionProjectLoggerWrapper } from '../../../shared/pxyzlogger';
import PACKAGE from '../../package.json';
import { loggerConfig } from '../config';

const version = PACKAGE.version;

export const logger = new VisionProjectLoggerWrapper(
  loggerConfig.loggerClientId,
  version
);
