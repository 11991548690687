import styled from '@emotion/styled';
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import { keyframes } from '@emotion/core';

import Asset from '../../assets/images/how-it-works.png';
import { Background } from '../../../../shared/components/background';
import { Button } from '../../../../shared/components/button';
import { Content } from '../../../../shared/components/content';
import { Section } from '../../../../shared/components/section';
import { Spacing } from '../../../../shared/components/spacing';
import { HeaderSmall } from '../../../../shared/composites/headerSmall';
import { cameraContent } from '../../core/data';
import { useExtensionStore } from '../../stores/store';
import { getChromeExtId } from '../../core/utils';
import { useEffect } from 'react';
import { logger } from '../../core/logger';
import { LogType } from '../../../../shared/types/enums';

const IframeStyled = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
`;

const FrameContainer = styled.div`
  position: relative;
  width: 325px;
  height: 243px;
  margin: auto;
  img {
    width: 100%;
  }
`;

const ContentStyled = styled.div`
  max-width: 400px;
  margin: auto;
`;

const dotsAnimation = keyframes`
    0%, 12.5% {
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    37.5% {
        text-shadow: .5em 0;
    }
    50% {
        text-shadow: .5em 0, 1em 0;
    }
    62.5%, 100% {
        text-shadow: .5em 0, 1em 0, 1.5em 0;
    }
    75% {
        text-shadow: .5em 0, 1em 0, 1.5em 0, 2em 0;
    }
    87.5%, 100%{
        text-shadow: .5em 0, 1em 0, 1.5em 0, 2em 0, 2.5em;
    }
`;

// Animate loading dots after the text within the span
const LoadingSpan = styled.span`
  &:after {
    content: ' .';
    animation: ${dotsAnimation} 1s steps(1, end) infinite;
  }
`;

export const Camera: React.FunctionComponent = observer(() => {
  const [stage, setStage] = useState<string>('howItWorks');
  const [inject, setInject] = useState<boolean>(false);
  const history = useHistory();
  const extensionStore = useExtensionStore();
  let container = <img src={Asset} alt="Vision Mode" />;
  let extensionFrame;
  let buttonHandler = undefined;
  let errorLink;

  const [buttonText, setButtonText] = useState<string | React.ReactNode>(
    cameraContent[stage].button
  );

  // Request the camera permission from the extension.
  // We want to run everytime in case user refresh page
  // if we pass in an empty array and user accidentally refresh the page the button will be stuck on the 'loading' status
  useEffect(() => {
    extensionStore.requestCameraPermissions();
  });

  // Run the hook only when the camera permission is changing
  useEffect(() => {
    // Check if we are in the correct stage & the camera permission state is not loaded
    if (stage === 'howItWorks' && extensionStore.cameraPermissions) {
      const dataToLog = {
        logType: LogType.CAMERA_PERMISSIONS,
        cameraPermissions: extensionStore.cameraPermissions,
      };
      switch (extensionStore.cameraPermissions) {
        // camera permission granted, go to the setup step
        case 'granted':
          logger.log('camera permission granted', { ...dataToLog });
          setStage('setup');
          setInject(false);
          break;
        // if camera permissions are denied, redirect to the error page
        case 'denied':
          logger.warn('camera access denied', { ...dataToLog });
          history.push('/camera-permissions');
          break;
        case 'prompt':
          logger.log('prompt permission state received', { ...dataToLog });
          break;
        default:
          logger.log(
            `unrecognised camera state ${extensionStore.cameraPermissions}`,
            { ...dataToLog }
          );
          break;
      }
    }
  }, [extensionStore.cameraPermissions]);

  const turnOnWebcam = (): void => {
    setButtonText(<LoadingSpan>Loading</LoadingSpan>);
    setInject(true);
  };

  const onOptimiseClick = (): void => {
    history.push('/optimisation?setup=true');
  };

  // determine the button handler based on the stage
  switch (stage) {
    case 'howItWorks':
      buttonHandler = turnOnWebcam;
      break;
    case 'setup':
      buttonHandler = onOptimiseClick;
      break;
  }

  if (stage == 'setup') {
    container = (
      <IframeStyled
        src={`chrome-extension://${getChromeExtId()}/ui/eye-tracking.html?preview=true`}
        allow="camera"
      />
    );
  }

  if (inject) {
    logger.log('injecting extension tracker');
    extensionFrame = (
      <iframe
        src={`chrome-extension://${getChromeExtId()}/ui/eye-tracking.html?preview=true`}
        allow="camera"
        style={{ display: 'none' }}
      />
    );
  }

  return (
    <Section>
      <Background />
      {extensionFrame}
      <Content>
        <Spacing size={60} unit={'px'} />
        <HeaderSmall label="Vision Mode" />
        <div className="centered">
          <Spacing size={40} unit={'px'} />
          <FrameContainer>{container}</FrameContainer>
          <ContentStyled>
            <Spacing size={20} unit={'px'} />
            <h4>{cameraContent[stage].title}</h4>
            <Spacing size={20} unit={'px'} />
            <span>{cameraContent[stage].description}</span>
            <Spacing size={40} unit={'px'} />
            <Button
              clickHandler={buttonHandler}
              label={
                stage == 'setup' ? cameraContent[stage].button : buttonText
              }
              disabled={false}
              minWidth="220px"
            />
            {errorLink}
          </ContentStyled>
        </div>
      </Content>
    </Section>
  );
});
