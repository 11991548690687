export interface Message {
  pxyz: {
    data:
      | WebGazerData
      | TrackinReadyData
      | ProgressData
      | StudyDetailsRequestData
      | CompletionData
      | RemoveCookieData
      | ActiveData
      | CameraPermissions;
  };
}

export interface WebGazerData {
  gaze: {
    x: number;
    y: number;
  };
  isFaceInValidationBox: boolean;
}

export interface TrackinReadyData {
  eyeTrackingIsReady: boolean;
}

export interface ProgressData {
  duration: number;
  progress: number;
}

export interface StudyDetailsRequestData {
  request: string;
}

export interface CompletionData {
  code: string;
}

export interface RemoveCookieData {
  removeCookie: boolean;
}

export interface ActiveData {
  isActive: boolean;
}

export interface CameraPermissions {
  cameraPermissions: string;
}

export const setMessageWebGazerData = (data: WebGazerData): Message => {
  return {
    pxyz: {
      data: data,
    },
  };
};

export const setMessageTrackinReadyData = (data: TrackinReadyData): Message => {
  return {
    pxyz: {
      data: data,
    },
  };
};

export const onMessageWebGazerData = (
  e: MessageEvent,
  callback: (data: WebGazerData) => void
): void => {
  const message: Message = e.data;
  if (
    message.pxyz &&
    (message.pxyz.data as WebGazerData).isFaceInValidationBox != undefined
  ) {
    callback(message.pxyz.data as WebGazerData);
  }
};

export const onMessageTrackinReadyData = (
  e: MessageEvent,
  callback: (data: TrackinReadyData) => void
): void => {
  const message: Message = e.data;
  if (
    message.pxyz &&
    (message.pxyz.data as TrackinReadyData).eyeTrackingIsReady != undefined
  ) {
    callback(message.pxyz.data as TrackinReadyData);
  }
};

export const onMessageProgressData = (
  e: Message,
  callback: (data: ProgressData) => void
): void => {
  if (e.pxyz && (e.pxyz.data as ProgressData).progress != undefined) {
    callback(e.pxyz.data as ProgressData);
  }
};

export const onMessageCameraPermissions = (
  e: Message,
  callback: (data: CameraPermissions) => void
): void => {
  callback(e.pxyz.data as CameraPermissions);
};

export const onMessageStudyDetailsRequest = (
  e: Message,
  callback: (data: StudyDetailsRequestData) => void
): void => {
  if (e.pxyz && (e.pxyz.data as StudyDetailsRequestData).request != undefined) {
    callback(e.pxyz.data as StudyDetailsRequestData);
  }
};

export const onMessageCompletionData = (
  e: Message,
  callback: (data: CompletionData) => void
): void => {
  if (e.pxyz && (e.pxyz.data as CompletionData).code != undefined) {
    callback(e.pxyz.data as CompletionData);
  }
};

export const onMessageActiveData = (
  e: Message,
  callback: (data: ActiveData) => void
): void => {
  if (e.pxyz && (e.pxyz.data as ActiveData).isActive != undefined) {
    callback(e.pxyz.data as ActiveData);
  }
};
