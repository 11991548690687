import { createContext, useContext } from 'react';
import { VisionProjectCookieStore } from './vpCookie';
import { ExtensionStore } from './extension';

const VPCookieStoreContext = createContext(new VisionProjectCookieStore());

export const useVPCookieStore = (): VisionProjectCookieStore => {
  const store = useContext(VPCookieStoreContext);
  return store;
};

const extensionStore = new ExtensionStore();
extensionStore.connectToExtension();
const ExtensionStoreContext = createContext(extensionStore);

export const useExtensionStore = (): ExtensionStore => {
  const store = useContext(ExtensionStoreContext);
  return store;
};
