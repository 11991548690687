import styled from '@emotion/styled';
import * as React from 'react';

import BackgroundSplashLeft from '../../assets/images/bg-splash-left.svg';
import BackgroundSplashRight from '../../assets/images/bg-splash-right.svg';

const WavesLeft = styled.div`
  position: absolute;
  height: 100vh;
  bottom: 0;
  left: 0;
  overflow: hidden;
  max-width: 100%;
  svg {
    height: 100vh;
  }
`;

const WavesRight = styled.div`
  position: absolute;
  height: 100vh;
  top: 0;
  right: 0;
  overflow: hidden;
  text-align: right;
  svg {
    height: 100vh;
  }
`;

interface WavesProps {
  side: 'left' | 'right' | 'both';
}

export const Waves: React.FunctionComponent<WavesProps> = (props) => {
  const { side } = props;
  let content;
  switch (side) {
    case 'left':
      content = (
        <React.Fragment>
          <WavesLeft>
            <BackgroundSplashLeft />
          </WavesLeft>
        </React.Fragment>
      );
      break;
    case 'right':
      content = (
        <React.Fragment>
          <WavesRight>
            <BackgroundSplashRight />
          </WavesRight>
        </React.Fragment>
      );
      break;
    case 'both':
      content = (
        <React.Fragment>
          <WavesLeft>
            <BackgroundSplashLeft />
          </WavesLeft>
          <WavesRight>
            <BackgroundSplashRight />
          </WavesRight>
        </React.Fragment>
      );
      break;
  }
  return content;
};
