/* eslint-disable @typescript-eslint/camelcase */
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/remote-config';
import 'firebase/compat/analytics';
import { logger } from '../core/logger';
import { FIRESTORE_CONFIG, DISABLE_REMOTE_CFG_FETCH_LIMIT } from '../config';

const firebaseConfig = {
  apiKey: FIRESTORE_CONFIG.apiKey,
  authDomain: FIRESTORE_CONFIG.authDomain,
  projectId: FIRESTORE_CONFIG.projectId,
  appId: FIRESTORE_CONFIG.appId,
  measurementId: FIRESTORE_CONFIG.measurementId,
};

const app = firebase.initializeApp(firebaseConfig);
const remoteConfig = firebase.remoteConfig(app);

if (DISABLE_REMOTE_CFG_FETCH_LIMIT) {
  remoteConfig.settings.minimumFetchIntervalMillis = 0;
}

const analytics = firebase.analytics();

export const setValUserProperty = (version: string) => {
  analytics.setUserProperties({ val_region_version: version });
};

export const fetchRemoteConfig = async (): Promise<string | undefined> => {
  return remoteConfig
    .fetchAndActivate()
    .then(() => {
      return remoteConfig.getValue('validation_regions_config').asString();
    })
    .catch((err: any) => {
      logger.error('fetch err', err);
      return undefined;
    });
};
