import styled from '@emotion/styled';
import React from 'react';

import { ReactProps } from '../core/interfaces';

interface IconProps {
  height: number;
  width: number;
  color?: string;
  stroke?: string;
}

const IconStyled = styled.div<IconProps>`
  display: inline-block;
  width: ${({ width }): string => width + 'px'};
  height: ${({ height }): string => height + 'px'};
  svg {
    width: ${({ width }): string => width + 'px'};
    height: ${({ height }): string => height + 'px'};
    fill: ${({ color }): string => color || '#fff'};
    stroke: ${({ stroke }): string => stroke || '#fff'};
  }
`;

export const Icon: React.FunctionComponent<IconProps & ReactProps> = ({
  children,
  width,
  height,
  color,
  stroke,
}: IconProps & ReactProps) => {
  return (
    <IconStyled width={width} height={height} color={color} stroke={stroke}>
      {children}
    </IconStyled>
  );
};
