import styled from '@emotion/styled';
import React from 'react';

import { ReactProps } from '../../../../shared/core/interfaces';

const IconTabStyled = styled.div`
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  transition: background-image 0.5s linear;
  &:hover {
    cursor: pointer;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
`;

interface IconTabProps {
  hoverHandler?(): void;
  outHandler?(): void;
}

export const IconTab: React.FunctionComponent<ReactProps & IconTabProps> = (
  props: ReactProps & IconTabProps
) => {
  const { children, hoverHandler, outHandler } = props;
  const onOver = (): void => {
    if (hoverHandler) {
      hoverHandler();
    }
  };
  const onOut = (): void => {
    if (outHandler) {
      outHandler();
    }
  };
  return (
    <IconTabStyled onMouseOut={onOut} onMouseOver={onOver}>
      {children}
    </IconTabStyled>
  );
};
