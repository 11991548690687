import styled from '@emotion/styled';
import React from 'react';

const BulletStyled = styled.div`
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.1),
    transparent
  );
  font-size: 1.2em;
  border-radius: 99px;
  text-align: left;
`;

const InnerBullet = styled.div`
  display: inline-block;
  font-family: 'Avenir', helvetica, arial, sans-serif;
  background-color: #fff;
  letter-spacing: 3px;
  font-size: 0.9em;
  color: #2d1e37;
  border-radius: 99px;
  padding: 10px 30px;
  text-align: center;
`;

const BulletText = styled.div`
  display: inline-block;
  margin-left: 3%;
`;

interface BulletProps {
  label: string;
  text: string;
}

export const Bullet: React.FunctionComponent<BulletProps> = (
  props: BulletProps
) => {
  const { label, text } = props;
  return (
    <BulletStyled>
      <InnerBullet>{label}</InnerBullet>
      <BulletText>{text}</BulletText>
    </BulletStyled>
  );
};
