import styled from '@emotion/styled';
import React from 'react';

import { ReactProps } from '../core/interfaces';

interface GridProps {
  container?: boolean;
  item?: boolean;
  justifyContent?:
    | 'space-evenly'
    | 'space-between'
    | 'space-around'
    | 'flex-start'
    | 'flex-end';
  alignItems?: 'baseline' | 'center' | 'flex-start' | 'flex-end';
  direction?: 'column' | 'row';
  size?: 10 | 15 | 20 | 25 | 30 | 40 | 50 | 60 | 70 | 80 | 85 | 90 | 100;
  alignSelf?: 'baseline' | 'center' | 'flex-start' | 'flex-end';
}

const GridContainerStyled = styled.div<GridProps>`
  display: flex;
  width: 100%;
  position: relative;
  align-items: ${({ alignItems }): string => alignItems || 'initial'};
  justify-content: ${({ justifyContent }): string =>
    justifyContent || 'initial'};
  flex-direction: ${({ direction }): string => direction || 'initial'};
  flex-flow: wrap;
`;

const GridItemStyle = styled.div<GridProps>`
  position: relative;
  align-items: ${({ alignItems }): string => alignItems || 'initial'};
  flex: 1 1 ${({ size }): string => size + '%'};
  max-width: ${({ size }): string => size + '%'};
  align-self: ${({ alignSelf }): string => alignSelf || 'initial'};
`;

export const Grid: React.FunctionComponent<GridProps & ReactProps> = ({
  container,
  item,
  children,
  justifyContent,
  alignItems,
  direction,
  size,
  className,
  alignSelf,
}: GridProps & ReactProps) => {
  if (container) {
    return (
      <GridContainerStyled
        justifyContent={justifyContent}
        alignItems={alignItems}
        direction={direction}
      >
        {children}
      </GridContainerStyled>
    );
  } else if (item) {
    return (
      <GridItemStyle
        size={size}
        alignItems={alignItems}
        justifyContent={justifyContent}
        className={className}
        alignSelf={alignSelf}
      >
        {children}
      </GridItemStyle>
    );
  }
  return (
    <div style={{ color: 'orange' }}>
      /!\ Assign a type to the Grid Component (container or item)
    </div>
  );
};
