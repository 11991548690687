import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { setDisplayPageAnalytics } from '../../services/analyticService';

const IframeStyled = styled.iframe`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`;

export const PrivacyNotice: React.FunctionComponent = () => {
  useEffect(() => {
    setDisplayPageAnalytics({page: "privacyNoticePage"});
  }, []);
  return (
    <IframeStyled src="https://docs.google.com/document/d/e/2PACX-1vTr2FYWlaBmy7YcXlkQ5V8xafvqZVqs4zFHBBUiFsXyFH6nIMkKsjjIym4jCQjCGldmhQ19nLOaZlaf/pub?embedded=true" />
  );
};
