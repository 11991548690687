import styled from '@emotion/styled';
import * as React from 'react';

interface TrackerProps {
  itemCount: number;
  currentIndex: number;
  clickHandler?(index: number): void;
}

const TrackerStyled = styled.div`
  position: fixed;
  z-index: 1;
  width: 10px;
  top: 50%;
  transform: translateY(-50%);
  right: 85px;
`;

const TrackerItemStyled = styled.a`
  position: relative;
  display: block;
  width: 10px;
  height: 10px;
  margin: 40px 0px;
  border: 1px solid white;
  border-radius: 100px;
  box-sizing: border-box;
  transition: border 0.5s, background 0.5s;
  background: rgba(237, 25, 98, 0);
  cursor: pointer;
  &.active {
    background: rgb(237, 25, 98);
    border: 1px solid rgb(237, 25, 98);
  }
`;

export const Tracker: React.FunctionComponent<TrackerProps> = (
  props: TrackerProps
) => {
  const { itemCount, currentIndex, clickHandler } = props;
  const onItemClick = (index: number): void => {
    if (clickHandler) clickHandler(index);
  };
  const items: JSX.Element[] = [];
  for (let i = 0; i < itemCount; i++) {
    const active = i <= currentIndex ? 'active' : '';
    items.push(
      <TrackerItemStyled
        key={`tracker-${i}`}
        onClick={(): void => {
          onItemClick(i);
        }}
        className={`${active}`}
      />
    );
  }
  return <TrackerStyled>{items}</TrackerStyled>;
};
