import styled from '@emotion/styled';
import React from 'react';

import { ProgressBar } from '../components/progress';
import { Spacing } from '../components/spacing';

const ProgressContentStyled = styled.div`
  display: flex;
  position: relative;
  margin: 0 auto;
  padding-left: 10px;
  max-width: 300px;
`;

const ProgressIndicatorContainer = styled.div`
  flex-grow: 1;
  margin-left: 15px;
  height: 24px;
  line-height: 24px;
`;

const Timer = styled.div`
  width: 100px;
  font-size: 0.8em;
  line-height: 0.3em;
  margin: auto;
  margin-bottom: 10px;
  span {
    font-size: 2.4em;
    font-weight: bold;
  }
`;

const ProgressBarContainer = styled.div`
  width: 250px;
  margin: auto;
`;

const ProgressInfo = styled.div`
  margin-top: 5px;
  font-size: 0.8em;
  text-align: center;
`;

interface TimerProgressProps {
  progress: number;
  duration: number;
  label: string;
}

export const TimerProgress: React.FunctionComponent<TimerProgressProps> = (
  props: TimerProgressProps
) => {
  const { progress, duration, label } = props;
  let info;
  if (label) {
    info = (
      <React.Fragment>
        <Spacing size={15} unit="px" />
        <ProgressInfo>{label}</ProgressInfo>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <Timer>
        <span>{duration}</span> mins remaining
      </Timer>
      <Spacing size={15} unit="px" />
      <ProgressContentStyled>
        <ProgressBarContainer>
          <ProgressBar limit={100} progress={progress} />
        </ProgressBarContainer>
        <ProgressIndicatorContainer>
          {progress.toFixed()}%
        </ProgressIndicatorContainer>
      </ProgressContentStyled>
      {info}
    </React.Fragment>
  );
};
