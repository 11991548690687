import { PxyzLogger, PXYZ_LOGGER_ENV } from '@playgroundxyz/playground-logger';
export class VisionProjectLoggerWrapper {
  private logger: PxyzLogger;

  constructor(loggerClientId: string, private version: string) {
    this.logger = new PxyzLogger({
      clientId: loggerClientId,
      env: this.getEnvironment(),
      options: {
        logging: {
          gcp: {
            disabled: process.env.NODE_ENV === 'testing',
          },
        },
      },
    });
  }

  addDetailsToPayload = (payload: any) => ({
    logTimestamp: Date.now(),
    url: window.location.href,
    version: this.version,
    ...payload,
  });

  addGlobalMetadata = (payload: any) => this.logger.addGlobalMetadata(payload);

  debug = (message: string, payload?: any): void =>
    this.logger.debug(message, this.addDetailsToPayload(payload));

  error = (message: string, payload?: any): void =>
    this.logger.error(message, this.addDetailsToPayload(payload));

  flush = () => {
    this.logger.flush();
  };
  getEnvironment = (): PXYZ_LOGGER_ENV => {
    switch (process.env.NODE_ENV) {
      case 'production':
      case 'prod':
        return PXYZ_LOGGER_ENV.PRODUCTION;
      case 'testing':
      case 'test':
        return PXYZ_LOGGER_ENV.TESTING;
      default:
        return PXYZ_LOGGER_ENV.STAGING;
    }
  };

  getGlobalMetadata = () => {
    return this.logger.getGlobalMetadata();
  };

  log = (message: string, payload?: any) =>
    this.logger.log(message, this.addDetailsToPayload(payload));

  setGlobalMetadata = (payload: any) =>
    this.logger.setGlobalMetadata({ ...this.getGlobalMetadata(), ...payload });

  warn = (message: string, payload?: any) =>
    this.logger.warn(message, this.addDetailsToPayload(payload));
}
