import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { setDisplayPageAnalytics } from '../../services/analyticService';

const IframeStyled = styled.iframe`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`;
export const TermsOfUseAgreement: React.FunctionComponent = () => {
  useEffect(() => {
    setDisplayPageAnalytics({page: "termsOfUseAgreementPage"});
  }, []);
  return (
    <IframeStyled src="https://docs.google.com/document/d/e/2PACX-1vSaxoYnueViWLKNM-uWxPOrIQh8KfkJrRlAyVrLKvoBwCypbueYNf_-Q5mBSHTWEuTPJruNJlmRHNcP/pub?embedded=true" />
  );
};
