import styled from '@emotion/styled';
import React from 'react';

import Logo from '../../../../shared/assets/images/logo.svg';
import { Content } from '../../../../shared/components/content';
import { Grid } from '../../../../shared/components/grid';
import { Icon } from '../../../../shared/components/icon';
import { ReactProps } from '../../../../shared/core/interfaces';
import { getPathFromRouteId } from '../../core/routes';

const FooterStyled = styled.div`
  width: 100%;
  background-color: #28242a;
  padding-top: 30px;
  padding-bottom: 150px;
`;

const FooterLinkStyled = styled.a`
  line-height: 1.9em;
  margin: 0;
  font-size: 1.2em;
  font-weight: 300;
  text-decoration: none;
`;

export const Footer: React.FunctionComponent<ReactProps> = () => {
  return (
    <FooterStyled>
      <Content>
        <Grid container>
          <Grid item size={50}>
            <Icon width={100} height={100}>
              <Logo />
            </Icon>
          </Grid>
          <Grid item size={50}>
            <Grid container justifyContent="flex-end">
              <Grid item size={25}>
                <FooterLinkStyled
                  href={getPathFromRouteId('privacy-policy')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </FooterLinkStyled>
              </Grid>
              <Grid item size={25}>
                <FooterLinkStyled
                  href={getPathFromRouteId('terms-of-use-agreement')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Use
                </FooterLinkStyled>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Content>
    </FooterStyled>
  );
};
