import styled from '@emotion/styled';
import * as React from 'react';

interface SpacingProps {
  size: number;
  unit: 'px' | '%' | 'vh' | 'vw';
}

const SpacingStyled = styled.div<SpacingProps>`
  height: ${({ size, unit }): string => size + unit};
  margin: 0;
  padding: 0;
`;

export const Spacing: React.FunctionComponent<SpacingProps> = (
  props: SpacingProps
) => {
  return <SpacingStyled {...props} />;
};
