import { VP_API_URL } from '../config';

export const clearCache = (
  campaignId: string,
  userId: string
): Promise<Response> => {
  return fetch(
    `${VP_API_URL}/adserver/user?campaignId=${campaignId}&key=${campaignId}-${userId}`,
    {
      method: 'DELETE',
    }
  );
};
