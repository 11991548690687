/* eslint-disable @typescript-eslint/camelcase */
import { GA4React } from 'ga-4-react';
import { GOOGLE_ANALYTIC_MEASUREMENT_ID } from '../config';
// Turn on debug mode to test real time analytics in debug view
// eslint-disable-next-line
const ga4react = new GA4React(GOOGLE_ANALYTIC_MEASUREMENT_ID, {
  debug_mode: false,
}).initialize();

interface AnalyticsUserData {
  userId: string;
  feedId: string;
  platformId: string;
  adCampaignId: string;
  surveyId: string;
}

interface AnalyticsPageData {
  page: string;
}

interface AnalyticsNotificationData {
  notification: string;
  message: string;
  page: string;
}

interface AnalyticsClickData {
  action: string;
  page: string;
}

interface AnalyticsErrorData {
  error: string;
  message: string;
  page: string;
}

export const setUserPropertiesAnalytics = (data: AnalyticsUserData): void => {
  const { userId, feedId, platformId, adCampaignId, surveyId } = data;
  ga4react
    .then((ga) => {
      ga.gtag('set', 'user_properties', {
        user_id: userId,
        study_id: feedId,
        platform_id: platformId,
        adCampaign_id: adCampaignId,
        survey_id: surveyId,
      });
    })
    .catch((err) => console.error(`Analytics failed: ${err}`));
};

export const setDisplayPageAnalytics = (data: AnalyticsPageData): void => {
  const { page } = data;
  ga4react
    .then((ga) => {
      ga.gtag('event', `display_${page}`, {
        page_name: page,
      });
    })
    .catch((err) => console.error(`Analytics failed: ${err}`));
};

export const setDisplayNotificationAnalytics = (
  data: AnalyticsNotificationData
): void => {
  const { notification, message, page } = data;
  ga4react
    .then((ga) => {
      ga.gtag('event', `display_${notification}`, {
        notification_message: message,
        page_name: page,
      });
    })
    .catch((err) => console.error(`Analytics failed: ${err}`));
};

export const onClickAnalytics = (data: AnalyticsClickData): void => {
  const { action, page } = data;
  ga4react
    .then((ga) => {
      ga.gtag('event', `tap_${action}`, {
        page_name: page,
      });
    })
    .catch((err) => console.error(`Analytics failed: ${err}`));
};

export const setErrorAnalytics = (data: AnalyticsErrorData): void => {
  const { error, message, page } = data;
  ga4react
    .then((ga) => {
      ga.gtag('event', `error_${error}`, {
        error_message: message,
        page_name: page,
      });
    })
    .catch((err) => console.error(`Analytics failed: ${err}`));
};
