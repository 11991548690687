import 'setimmediate';

import * as winston from 'winston';

const logStyle: { [id: string]: string } = {
  debug: 'color: #03A9F4;',
  info: 'color: #4CAF50;',
  warn: 'color: #FF9800;',
  error: 'color: #FF5722',
};

export const Logger = winston.createLogger({
  level: process.env['LOG_LEVEL'] ? process.env['LOG_LEVEL'] : 'debug',
  transports: new winston.transports.Console({
    format: winston.format.combine(winston.format.simple()),
    log: (info, next) => {
      console.log(`%c ${info.level} : ${info.message}`, logStyle[info.level]);
      next();
    },
  }),
});
