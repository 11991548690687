import React from 'react';
import { getPathFromRouteId } from '../../core/routes';
import { ErrorTemplate } from '../../composites/error/errorTemplate';

const onSubmit = (): void => {
  const faqUrl = `${getPathFromRouteId(`faq`)}/#troubleshooting`;
  window.open(faqUrl, '_blank');
};

export const AdBlockerDetected: React.FunctionComponent = () => {
  const tryAgainUrl = getPathFromRouteId(`privacy`);

  return (
    <ErrorTemplate
      headerSmall={'AD BLOCKER DETECTED'}
      title={'It looks like you have an adblocker running'}
      content={`To complete this study, please disable any ad blockers you
                have installed. Visit our Help Page for guidance on how to resolve this.`}
      button={{
        label: `Help page`,
        disabled: false,
        clickHandler: onSubmit,
      }}
      link={{
        href: tryAgainUrl,
        label: `I've fixed it! Try Again`,
      }}
    />
  );
};
