import styled from '@emotion/styled';
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { FeatureItem } from 'src/core/interfaces';
import { useVPCookieStore, useExtensionStore } from '../../stores/store';
import toaster from 'toasted-notes';
import { Button } from '../../../../shared/components/button';
import { Content } from '../../../../shared/components/content';
import { Grid } from '../../../../shared/components/grid';
import { Icon } from '../../../../shared/components/icon';
import { ProgressBar } from '../../../../shared/components/progress';
import { Section } from '../../../../shared/components/section';
import { Spacing } from '../../../../shared/components/spacing';
import { Logger } from '../../../../shared/logger';
import Background0 from '../../assets/images/bg0.svg';
import Tick from '../../assets/images/tick.svg';
import Crosses from '../../assets/images/xxs.svg';
import { Checkbox } from '../../components/checkbox/checkbox';
import { Tracker } from '../../components/tracker/tracker';
import { Footer } from '../../composites/footer/footer';
import { Header } from '../../composites/header/header';
import { NavigationNext } from '../../composites/navigationNext/navigationNext';
import { checkListItems, featureItems } from '../../core/data';
import { getChromeExtId } from '../../core/utils';
import { clearCache } from '../../core/vpApi';
import { DISABLE_EXTERNAL_MONITOR_CHECK } from '../../config';
import {
  setDisplayPageAnalytics,
  onClickAnalytics,
  setDisplayNotificationAnalytics,
} from '../../services/analyticService';

gsap.registerPlugin(ScrollToPlugin);

const translate = 4;
const perspective = 8;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #2d1d37;
`;

const ParallaxStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  perspective: ${(): string => perspective + 'px'};
  perspective-origin: 0%;
  display: flex;
  overflow-x: hidden;
  overflow-y: scroll;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform-origin: 0;
  transform: translateZ(${(): string => -translate + 'px'})
    scale(${(): number => (perspective + translate) / perspective});
`;

const Foreground = styled.div`
  width: 100%;
  transform: translateZ(0px);
`;

const Box = styled.div`
  width: 15px;
  height: 150px;
  background-color: #fff;
`;

const Section1Crosses = styled.div`
  position: absolute;
  width: 512px;
  top: 65px;
  right: -90px;
`;

const Section1Box = styled.div`
  position: absolute;
  right: calc(70px - 2.5px);
  bottom: 20%;
`;

const Section2Box = styled.div`
  position: absolute;
  top: -75px;
`;

const Section2Crosses = styled.div`
  top: -60px;
  position: absolute;
  width: 512px;
  right: -90px;
`;

const Section3Count = styled.div`
  padding-left: 20px;
  display: inline-block;
  vertical-align: 5px;
`;

const Section3Crosses = styled.div`
  top: 50px;
  position: absolute;
  width: 512px;
  right: -90px;
`;

const Section3CheckItem = styled.div`
  margin-bottom: 10px;
  border-radius: 10px;
  display: flex;
  padding: 10px 0;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.1),
    transparent
  );
`;

const Section3CheckSeparator = styled.div`
  height: 60px;
  width: 1px;
  background-color: #fff;
  right: 30%;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
`;

const Section3Box = styled.div`
  position: absolute;
  top: 0;
  right: calc(70px - 2.5px);
`;

const StyledToast = styled.div`
  color: #000;
`;

const StyledSubmit = styled.div`
  max-width: 700px;
`;

export const Home: React.FunctionComponent<{}> = observer(() => {
  let wrapper: HTMLDivElement | null;
  const [checkedItems, setCheckedItems] = useState<{
    [id: string]: boolean;
  }>(
    checkListItems.reduce((list, item) => ((list[item.key] = false), list), {})
  );
  const [currentSection, setCurrentSection] = useState<number>(0);
  const extensionStore = useExtensionStore();
  const vpCookieStore = useVPCookieStore();
  const history = useHistory();

  let scrollEnabled = true;

  const notification = (message: string): void => {
    toaster.notify(<StyledToast>{message}</StyledToast>, {
      type: 'error',
      duration: null,
      position: 'top-right',
    });
  };

  toaster.closeAll();

  const sectionOffsets: number[] = [];

  useEffect(() => {
    setDisplayPageAnalytics({ page: 'homePage' });
    if (vpCookieStore.authorised) {
      clearCache(vpCookieStore.adCampaignId, vpCookieStore.userId);
    }
  }, []);

  useEffect(() => {
    [...document.getElementsByTagName('section')].map((item) => {
      sectionOffsets.push(item.offsetTop);
    });

    const params = new URLSearchParams(window.location.search);
    if (params.has('ext')) {
      if (!getChromeExtId()) {
        const message = 'The chrome extension is not installed';
        setDisplayNotificationAnalytics({
          notification: 'notification',
          message: message,
          page: 'homePage',
        });
        notification(message);
      }
      Logger.debug(
        `extension status \n  connecting:${extensionStore.connecting} \n  connected:${extensionStore.connected} \n  active:${extensionStore.active}`
      );
      gsap.set(wrapper, { scrollTo: '#checklist' });

      if (extensionStore.connected && extensionStore.active) {
        const items = checkedItems;
        items['extension'] = true;
      } else if (!extensionStore.connected && !extensionStore.connecting) {
        const message =
          'The chrome extension is not responding. Please reinstall it and refresh the page.';
        setDisplayNotificationAnalytics({
          notification: 'notification',
          message: message,
          page: 'homePage',
        });
        notification(message);
      } else if (!extensionStore.active && !extensionStore.connecting) {
        const message =
          "The chrome extension is disabled. To continue, right click the Vision Project chrome extension in your browser and select 'Enable Vision Project'";
        setDisplayNotificationAnalytics({
          notification: 'notification',
          message: message,
          page: 'homePage',
        });
        notification(message);
        toaster.notify(<StyledToast></StyledToast>, {
          type: 'error',
          duration: null,
          position: 'top-right',
        });
      }
    }
  });

  const onCheckBoxClick = (key: string): void => {
    onClickAnalytics({ action: `${key}CheckBoxClick`, page: 'homePage' });
    const items = checkedItems;
    items[key] = !items[key];
    setCheckedItems((checkedItems) => {
      return { ...checkedItems, ...items };
    });
  };

  const onExtensionCheckBoxClick = (): void => {
    onClickAnalytics({ action: 'extensionCheckBoxClick', page: 'homePage' });
    if (!checkedItems['extension']) {
      Logger.info('reload after extension installed');
      const url = new URL(window.location.href);
      if (url.searchParams.get('ext') !== 'true') {
        url.searchParams.append('ext', 'true');
      }
      window.location.href = url.href;
    }
  };

  const onNavigationClick = (sectionIndex: number): void => {
    if (sectionIndex != currentSection && scrollEnabled && wrapper) {
      scrollEnabled = false;
      setCurrentSection(sectionIndex);
      gsap
        .to(wrapper, {
          duration: 1,
          scrollTo: { y: sectionOffsets[sectionIndex] },
        })
        .eventCallback('onComplete', () => {
          scrollEnabled = true;
        });
    }
  };

  const onSubmit = (): void => {
    onClickAnalytics({ action: 'beginStudy', page: 'homePage' });
    // Checking external monitor
    // @ts-expect-error
    if (!DISABLE_EXTERNAL_MONITOR_CHECK && window.screen.isExtended) {
      history.push('/external-monitor-detected');
    } else {
      history.push('/privacy');
    }
  };

  const features = featureItems.map(
    (item: FeatureItem, index: number): JSX.Element => {
      const ItemIcon = item.icon;
      return (
        <Grid item size={25} key={`feature-grid-item${index}`}>
          <div className="centered">
            <Icon color="transparent" height={98} width={98}>
              <ItemIcon />
            </Icon>
            <Spacing size={30} unit="px" />
            <h5>{item.title}</h5>
            <Spacing size={25} unit="px" />
            {item.description}
          </div>
        </Grid>
      );
    }
  );

  const totalcheckItemCount = Object.entries(checkedItems).length;
  const checkedItemCount = Object.entries(checkedItems).filter(
    ([key, value]) =>
      value === true ||
      (key === 'extension' &&
        !extensionStore.connecting &&
        extensionStore.connected &&
        extensionStore.active)
  ).length;
  const checkList = checkListItems.map(
    (item): JSX.Element => {
      let link;
      if (item.link) {
        link = (
          <a href={item.link.url} target="_blank" rel="noopener noreferrer">
            {item.link.label}
          </a>
        );
      }
      return (
        <Section3CheckItem key={`check-${item.key}`}>
          <Grid container justifyContent="flex-start" alignItems="center">
            <Grid item size={20}>
              <Checkbox
                ticked={
                  item.key === 'extension'
                    ? !extensionStore.connecting &&
                      extensionStore.connected &&
                      extensionStore.active
                    : checkedItems[item.key]
                }
                itemKey={item.key}
                clickHandler={
                  item.key === 'extension'
                    ? onExtensionCheckBoxClick
                    : onCheckBoxClick
                }
              >
                <Tick />
              </Checkbox>
              <Section3CheckSeparator />
            </Grid>
            <Grid item size={80}>
              <h6>
                {item.title} {'  '}
                {link}
              </h6>
              <span>{item.description}</span>
            </Grid>
          </Grid>
        </Section3CheckItem>
      );
    }
  );

  return (
    <div>
      <Tracker
        itemCount={3}
        currentIndex={currentSection}
        clickHandler={onNavigationClick}
      />
      <Wrapper>
        <ParallaxStyled ref={(node): HTMLDivElement | null => (wrapper = node)}>
          <Background>
            <Background0 />
          </Background>
          <Foreground>
            <Section id="welcome">
              <Background />
              <Content>
                <div>
                  <Section1Crosses>
                    <Crosses />
                  </Section1Crosses>
                  <Header />
                </div>
                <Spacing size={130} unit="px" />
                <Grid container direction="column">
                  <Grid item size={50}>
                    <h1>
                      You are invited to take part in an innovative eye tracking
                      study
                    </h1>
                    <Spacing size={40} unit="px" />
                    <p>
                      Research like this is usually done in a lab with
                      specialised equipment. Now you can participate using a
                      webcam and computer, from anywhere in the world.
                    </p>
                  </Grid>
                </Grid>
                <Spacing size={130} unit="px" />
              </Content>
              <NavigationNext
                clickHandler={(): void => {
                  onNavigationClick(1);
                }}
              />
              <Section1Box>
                <Box />
              </Section1Box>
            </Section>
            <Section id="details" className="white-background">
              <Background />
              <Content>
                <Section2Crosses>
                  <Crosses />
                </Section2Crosses>
                <h1 className="centered">
                  Contribute to the latest in market research
                </h1>
                <Spacing size={10} unit="px" />
                <h2 className="centered">
                  Your insights help our partners improve their products
                </h2>
                <Spacing size={75} unit="px" />
                <Grid container justifyContent="space-around">
                  {features}
                </Grid>
              </Content>
              <NavigationNext
                clickHandler={(): void => {
                  onNavigationClick(2);
                }}
              />
              <Section2Box>
                <Box />
              </Section2Box>
            </Section>
            <Section id="checklist" freeHeight>
              <Background />
              <Content>
                <Section3Crosses>
                  <Crosses />
                </Section3Crosses>
                <Spacing size={140} unit="px" />
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="baseline"
                >
                  <Grid item size={30}>
                    <h1>Your Checklist</h1>
                  </Grid>
                  <Grid item size={20}>
                    <ProgressBar
                      limit={totalcheckItemCount}
                      progress={checkedItemCount}
                    />
                  </Grid>
                  <Grid item size={10}>
                    <Section3Count>
                      {checkedItemCount}/{totalcheckItemCount}
                    </Section3Count>
                  </Grid>
                </Grid>
                <Spacing size={25} unit="px" />
                <Grid item size={60}>
                  <span>Complete the steps below to begin the study:</span>
                  <Spacing size={40} unit="px" />
                  {checkList}
                  <Spacing size={20} unit="px" />
                </Grid>
                <StyledSubmit>
                  <span>
                    <b>Important:</b> This study should be completed
                    uninterrupted and in one sitting. Please ensure you will not
                    be distracted for the duration of the study.
                  </span>
                  <Spacing size={40} unit="px" />
                  <Button
                    label="Begin Study"
                    disabled={
                      checkedItemCount != totalcheckItemCount ||
                      !extensionStore.active ||
                      !extensionStore.connected
                    }
                    clickHandler={onSubmit}
                  />
                </StyledSubmit>
                <Spacing size={100} unit="px" />
              </Content>
              <Section3Box>
                <Box />
              </Section3Box>
            </Section>
            <Footer />
          </Foreground>
        </ParallaxStyled>
      </Wrapper>
    </div>
  );
});
