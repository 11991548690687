import React from 'react';
import { QUERY_STRING_PARAMS } from '../../../../shared/config';
import { getPathFromRouteId } from '../../core/routes';
import { ErrorTemplate } from '../../composites/error/errorTemplate';

const onSubmit = (): void => {
  const params = new URLSearchParams(location.search);
  let optimisationUrl = getPathFromRouteId(`optimisation`);

  if (params.has(QUERY_STRING_PARAMS.navigateTo)) {
    const urlEncoded = params.get(QUERY_STRING_PARAMS.navigateTo);
    optimisationUrl += `?${QUERY_STRING_PARAMS.navigateTo}=${urlEncoded}`;
  }
  window.location.href = optimisationUrl;
};

export const OutOfView: React.FunctionComponent = () => {
  return (
    <ErrorTemplate
      headerSmall={'EYE TRACKING ISSUE'}
      title={'Are you still there?'}
      content={`We’re having trouble conducting eye tracking. You may have moved
              away from the camera or your set up conditions have changed.
              Please update your eye tracking to continue the study.`}
      button={{
        label: 'Update Eye Tracking',
        disabled: false,
        clickHandler: onSubmit,
      }}
    />
  );
};
