import styled from '@emotion/styled';
import React from 'react';
import { Background } from 'react-parallax';
import { Waves } from '../../composites/waves/waves';
import { Content } from '../../../../shared/components/content';
import { Section } from '../../../../shared/components/section';
import { Spacing } from '../../../../shared/components/spacing';
import { HeaderSmall } from '../../../../shared/composites/headerSmall';

export const FetchError: React.FunctionComponent = () => {
  const ContentStyled = styled.div`
    max-width: 400px;
    margin: auto;
  `;
  return (
    <Section>
      <Background />
      <Waves side="both" />
      <Content>
        <Spacing size={60} unit={'px'} />
        <HeaderSmall label="NO RESPONSE" />
        <div className="centered">
          <Spacing size={40} unit={'px'} />
          <ContentStyled>
            <Spacing size={20} unit={'px'} />
            <h4>Sorry, something went wrong</h4>
            <Spacing size={20} unit={'px'} />
            <div>
              We could not get the response from our backend services. For
              further assistance please email support@vision-project.co
            </div>
            <Spacing size={40} unit={'px'} />
          </ContentStyled>
        </div>
      </Content>
    </Section>
  );
};
