import React from 'react';
import { getPathFromRouteId } from '../../core/routes';
import { ErrorTemplate } from '../../composites/error/errorTemplate';

const onSubmit = (): void => {
  // @ts-expect-error
  if (!window.screen.isExtended) {
    const tryAgainUrl = getPathFromRouteId(`privacy`);
    window.open(tryAgainUrl, '_blank');
  }
};

export const ExternalMonitorDetected: React.FunctionComponent = () => {
  const faqUrl = `${getPathFromRouteId(`faq`)}/#troubleshooting`;
  return (
    <ErrorTemplate
      headerSmall={'EXTERNAL MONITOR DETECTED'}
      title={'It looks like you have an external monitor connected'}
      content={`To complete this study, please disconnect all external monitors. Visit out Help Page for guidance on how to resolve this.`}
      button={{
        label: `I've fixed it! Try Again`,
        disabled: false,
        clickHandler: onSubmit,
      }}
      link={{
        href: faqUrl,
        label: `Help page`,
      }}
    />
  );
};
