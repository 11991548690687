import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { getPathFromRouteId } from '../../core/routes';

const AdBanner = styled.div`
  height: 1px;
  width: 1px;
  visibility: hidden;
  pointer-events: none;
  position: fixed;
  top: 1;
  left: 1;
`;

const AdBlockerDetect: React.FunctionComponent = () => {
  const fakeAd = useRef<HTMLDivElement>({} as HTMLDivElement);
  const [isAdBlockerOn, setIsAdBlockerOn] = useState(false);

  const handleIsAdBlockerOn = (): void => {
    const fakeAdRef = fakeAd.current;
    const isOn = fakeAdRef && fakeAdRef.offsetHeight === 0;
    setIsAdBlockerOn(isOn);
  };

  const redirectToErrorPage = (): void => {
    const faqUrl = getPathFromRouteId('ad-blocker-detected');
    window.location.href = faqUrl;
  };

  useEffect(() => {
    handleIsAdBlockerOn();
  }, []);

  useEffect(() => {
    if (isAdBlockerOn) {
      redirectToErrorPage();
    }
  }, [isAdBlockerOn]);

  return <AdBanner ref={fakeAd} className="adBanner" />;
};

export default AdBlockerDetect;
