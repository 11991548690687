export const QUERY_STRING_PARAMS = {
  urlKey: 'pxyz-url',
  stopRedirectKey: 'pxyz-stop-redirect',
  navigateTo: 'vp-navigate-to',
};

export const YOUTUBE_URL = 'https://www.youtube.com';

export const VP_LIB_TAG_ID = 'vp-lib-script-tag';
export const VP_LIB_ATTRIBUTE = 'vp-lib-config';
export const INSPECT_VP_CONTEXT = 'inspect-vp-context';
