import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Button } from '../../../../shared/components/button';
import { Content } from '../../../../shared/components/content';
import { Grid } from '../../../../shared/components/grid';
import { Icon } from '../../../../shared/components/icon';
import { Section } from '../../../../shared/components/section';
import { Spacing } from '../../../../shared/components/spacing';
import { HeaderSmall } from '../../../../shared/composites/headerSmall';
import { QUERY_STRING_PARAMS } from '../../../../shared/config';
import BackdropGIF from '../../assets/images/backdrop.gif';
import Camera from '../../assets/images/camera-optimisation.svg';
import Eye from '../../assets/images/eye.svg';
import HeadGIF from '../../assets/images/head.gif';
import LightingGIF from '../../assets/images/lighting.gif';
import Lighting from '../../assets/images/lighting.svg';
import Optimise from '../../assets/images/optimiseyoursetup.jpg';
import PositionGIF from '../../assets/images/position.gif';
import Position from '../../assets/images/position.svg';
import { IconTab } from '../../composites/iconTab/iconTab';
import { getChromeExtId } from '../../core/utils';
import { useExtensionStore } from '../../stores/store';
import { Background } from '../../../../shared/components/background';

const TabsContainer = styled.div`
  max-width: 820px;
  margin: auto;
  width: 100%;
  position: relative;
`;

const IframeStyled = styled.iframe`
  width: 320px;
  height: 240px;
  border: 0;
  display: block;
`;

const LabelStyled = styled.div``;

const AnimContainer = styled.div`
  width: 320px;
  height: 290px;
  margin: auto;
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: -15px;
  right: -150px;
  text-align: right;
`;

const LoadingDiv = styled.div`
  margin: auto;
  position: absolute;
  top: 50%;
  left: 45%;
`;

export const Optimisation: React.FunctionComponent<{}> = observer(() => {
  const [animation, setAnimation] = useState<string>('');
  const extensionStore = useExtensionStore();
  const [checkedItems, setCheckedItems] = useState<{
    [id: string]: boolean;
  }>({
    lighting: false,
    position: false,
    camera: false,
    backdrop: false,
  });
  const history = useHistory();
  const location = useLocation();

  const iconTabOutHandler = (): void => {
    setAnimation('');
  };

  const iconTabOverHandler = (image: string, key: string): void => {
    setAnimation(image);
    const items = checkedItems;
    items[key] = true;
    setCheckedItems((checkedItems) => {
      return { ...checkedItems, ...items };
    });
  };

  const onSubmit = (): void => {
    const params = new URLSearchParams(location.search);
    let practiceUrl = `chrome-extension://${getChromeExtId()}/ui/index.html#/practice`;

    // check if we need to forward the url the user is navigating to
    // happens only when in article validation fails
    if (params.has(QUERY_STRING_PARAMS.navigateTo)) {
      const urlEncoded = params.get(QUERY_STRING_PARAMS.navigateTo);
      practiceUrl += `?${QUERY_STRING_PARAMS.navigateTo}=${urlEncoded}`;
    }
    window.location.href = practiceUrl;
  };

  let content = <LoadingDiv>loading...</LoadingDiv>;

  if (!extensionStore.connecting) {
    content = (
      <AnimContainer>
        <IframeStyled
          src={`chrome-extension://${getChromeExtId()}/ui/eye-tracking.html?preview=true`}
          allow="camera"
        />
        <LabelStyled>
          <img src={Optimise} alt="Optimise your setup" />
        </LabelStyled>
      </AnimContainer>
    );
  }

  if (animation != '') {
    content = <img src={animation} />;
  }

  const params = new URLSearchParams(history.location.search);
  const totalcheckItemCount = Object.entries(checkedItems).length;
  const checkedItemCount = Object.entries(checkedItems).filter(
    ([_key, value]) => value === true
  ).length;

  return (
    <Section>
      <Background />
      <Content>
        <HeaderSmall label="VISION MODE" />
        <Spacing size={30} unit="px" />
        <AnimContainer>{content}</AnimContainer>
        <Spacing size={20} unit="px" />
        <div className="centered">
          <span>Hover over each section to see a demo</span>
        </div>
        <Spacing size={20} unit="px" />
        <TabsContainer>
          <ButtonContainer>
            <Button
              label="I'm Ready"
              disabled={
                params.has('setup') && checkedItemCount !== totalcheckItemCount
              }
              clickHandler={onSubmit}
            />
          </ButtonContainer>
          <Grid container justifyContent="space-evenly">
            <Grid item size={20}>
              <IconTab
                hoverHandler={(): void => {
                  iconTabOverHandler(LightingGIF, 'lighting');
                }}
                outHandler={iconTabOutHandler}
              >
                <Icon height={48} width={48} color="transparent">
                  <Lighting />
                </Icon>
                <Spacing unit="px" size={10} />
                <span>Lighting</span>
                <Spacing unit="px" size={20} />
              </IconTab>
            </Grid>
            <Grid item size={20}>
              <IconTab
                hoverHandler={(): void => {
                  iconTabOverHandler(HeadGIF, 'position');
                }}
                outHandler={iconTabOutHandler}
              >
                <Icon height={48} width={48} color="transparent">
                  <Position />
                </Icon>
                <Spacing unit="px" size={10} />
                <span>Position</span>
                <Spacing unit="px" size={20} />
              </IconTab>
            </Grid>
            <Grid item size={20}>
              <IconTab
                hoverHandler={(): void => {
                  iconTabOverHandler(PositionGIF, 'camera');
                }}
                outHandler={iconTabOutHandler}
              >
                <Icon height={48} width={48} color="transparent">
                  <Camera />
                </Icon>
                <Spacing unit="px" size={10} />
                <span>Camera Position</span>
                <Spacing unit="px" size={20} />
              </IconTab>
            </Grid>
            <Grid item size={20}>
              <IconTab
                hoverHandler={(): void => {
                  iconTabOverHandler(BackdropGIF, 'backdrop');
                }}
                outHandler={iconTabOutHandler}
              >
                <Icon height={48} width={48} color="transparent">
                  <Eye />
                </Icon>
                <Spacing unit="px" size={10} />
                <span>Backdrop</span>
                <Spacing unit="px" size={20} />
              </IconTab>
            </Grid>
          </Grid>
        </TabsContainer>
      </Content>
    </Section>
  );
});
