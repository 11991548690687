import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Background } from '../../../../shared/components/background';
import { Content } from '../../../../shared/components/content';
import { Grid } from '../../../../shared/components/grid';
import { Section } from '../../../../shared/components/section';
import { Spacing } from '../../../../shared/components/spacing';
import { TimerProgress } from '../../../../shared/composites/timerProgress';
import Blur from '../../assets/images/blur.svg';
import Lock from '../../assets/images/lock.svg';
import Star from '../../assets/images/star.svg';
import { Bullet } from '../../components/bullet/bullet';
import { Header } from '../../composites/header/header';
import { Info } from '../../composites/info/info';
import { Waves } from '../../composites/waves/waves';
import { SiteDirectory } from '../siteDirectoy/siteDirectory';
import { feedTypeContent } from '../../core/feedType';
import { useVPCookieStore, useExtensionStore } from '../../stores/store';

export const TaskSummary: React.FunctionComponent = observer(() => {
  const extensionStore = useExtensionStore();
  const vpCookieStore = useVPCookieStore();

  const { taskSummaryContent } = feedTypeContent(vpCookieStore.feedType);

  let bulletText = 'YOUR MISSION';

  if (extensionStore.progress >= 100) {
    bulletText = 'MISSION COMPLETE';
  }

  const onFocus = (): void => {
    extensionStore.connectToExtension();
  };

  useEffect(() => {
    if (window) {
      window.addEventListener('focus', onFocus);
    }
    return (): void => {
      window.removeEventListener('focus', onFocus);
    };
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Vision Project Study`}</title>
      </Helmet>
      <Waves side="both" />
      <Section>
        <Background />
        <Content>
          <Header />
          <Spacing size={100} unit="px" />
          <h1>You are ready to begin the study</h1>
          <Spacing size={50} unit="px" />
          <Bullet label={bulletText} text={taskSummaryContent.bulletTitle} />
          <Spacing size={50} unit="px" />
          <Grid container>
            <Grid item size={60}>
              <Info icon={Star} text={taskSummaryContent.gridItems.item0} />
              <Spacing size={30} unit="px" />
              <Info
                icon={Blur}
                text={
                  <span>
                    <b>Accuracy Checkpoints.</b> We will interrupt your browsing
                    every couple minutes to review and update the accuracy of
                    your eye tracking. This is an important part of the study.
                  </span>
                }
              />
              <Spacing size={30} unit="px" />
              <Info icon={Lock} text={taskSummaryContent.gridItems.item2} />
            </Grid>
            <Grid item size={40} alignSelf="center">
              <TimerProgress
                duration={Math.round(extensionStore.duration / 60000)}
                progress={extensionStore.progress}
                label=""
              />
            </Grid>
          </Grid>
        </Content>
      </Section>
      <Section className="white-background" freeHeight>
        <Background />
        <SiteDirectory />
      </Section>
    </React.Fragment>
  );
});
