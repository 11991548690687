import React from 'react';
import { ErrorTemplate } from '../../composites/error/errorTemplate';
import { getPathFromRouteId } from '../../core/routes';

const onSubmit = (): void => {
  const faqUrl = `${getPathFromRouteId(`faq`)}/#tracking`;
  window.open(faqUrl, '_blank');
};

export const EyeTrackingBlocked: React.FunctionComponent = () => {
  const tryAgainUrl = getPathFromRouteId('camera');

  return (
    <ErrorTemplate
      headerSmall={'EYE TRACKING BLOCKED'}
      title={'We are having trouble conducting eye tracking'}
      content={`This could be due to Google Chrome or another application blocking
              access to cookies and/or other site data. Visit our help page for
              steps on how to resolve this.`}
      button={{
        label: 'Help Page',
        disabled: false,
        clickHandler: onSubmit,
      }}
      link={{
        href: tryAgainUrl,
        label: `I've fixed it! Try Again`,
      }}
    />
  );
};
