import styled from '@emotion/styled';
import * as React from 'react';

const initProgressSize = 12;
const progressHeight = 24;

const ProgressBarStyled = styled.div`
  width: 100%;
  height: ${progressHeight + 'px'};
  background-color: rgba(255, 255, 255, 0.3);
  display: inline-block;
  border-radius: 99px;
`;

const ProgressStyled = styled.div`
  background-color: #fff;
  height: ${initProgressSize + 'px'};
  width: ${initProgressSize + 'px'};
  border-radius: 99px;
  transition: width 0.2s;
  margin-top: ${(progressHeight - initProgressSize) / 2 + 'px'};
  margin-left: ${(progressHeight - initProgressSize) / 2 + 'px'};

  &.active {
    height: ${progressHeight + 'px'};
    background-image: linear-gradient(270deg, #ff8a3f 0%, #fe0060 100%);
    margin-top: 0;
    margin-left: 0;
  }
`;

interface ProgressBarProps {
  progress: number;
  limit: number;
  className?: string;
}

export const ProgressBar: React.FunctionComponent<ProgressBarProps> = (
  props: ProgressBarProps
) => {
  const { progress, limit, className } = props;
  let progressWidth = initProgressSize + 'px';
  let active = '';
  if (progress > 0) {
    progressWidth = `${((progress * 100) / limit).toString()}%`;
    active = 'active';
  }
  return (
    <ProgressBarStyled className={className}>
      <ProgressStyled className={active} style={{ width: progressWidth }} />
    </ProgressBarStyled>
  );
};
