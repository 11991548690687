import styled from '@emotion/styled';
import React from 'react';

import { ReactProps } from '../core/interfaces';

const ContentStyled = styled.div`
  position: relative;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Content: React.FunctionComponent<ReactProps> = ({
  children,
}: ReactProps) => {
  return <ContentStyled>{children}</ContentStyled>;
};
