import styled from '@emotion/styled';
import React, { useState, UIEvent, useEffect } from 'react';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react';
import Logo from '../../../../shared/assets/images/logo.svg';
import { Background } from '../../../../shared/components/background';
import { Button } from '../../../../shared/components/button';
import { Content } from '../../../../shared/components/content';
import { Grid } from '../../../../shared/components/grid';
import { Icon } from '../../../../shared/components/icon';
import { Section } from '../../../../shared/components/section';
import { Spacing } from '../../../../shared/components/spacing';
import Blur from '../../assets/images/blur.svg';
import Lock from '../../assets/images/lock.svg';
import Shield from '../../assets/images/shield.svg';
import Tick from '../../assets/images/tick.svg';
import { Checkbox } from '../../components/checkbox/checkbox';
import { Info } from '../../composites/info/info';
import { getPathFromRouteId } from '../../core/routes';
import AdBlockerDetect from '../adblockerDetect/adblockerDetect';
import { SEGMENT_AD_TARGETING_ID } from '../../config';
import { useExtensionStore, useVPCookieStore } from '../../stores/store';
import {
  onClickAnalytics,
  setDisplayPageAnalytics,
} from '../../services/analyticService';

const PrivacyContainer = styled.div`
  width: 100%;
  height: 63vh;
  background-color: #fff;
  color: #000;
  padding: 20px;
  overflow-y: scroll;
  font-size: 0.8em;
  h4 {
    line-height: 1.5em;
    margin: 0;
    font-size: 1.2em;
  }
  h5 {
    font-size: 1em;
    margin: 0;
  }
  h4,
  h5 {
    color: #000;
    font-weight: bold;
  }
  b {
    color: #000;
  }
  table {
    width: 100%;
    border: 1px solid #000;
    border-collapse: collapse;
    td,
    th {
      padding: 5px;
      border: 1px solid #000;
    }
    th {
      background-color: #000;
      width: 50%;
      text-align: left;
    }
    td {
      color: #000;
    }
  }
  li {
    color: #000;
    margin-bottom: 15px;
  }
  a {
    color: blue;
    text-decoration: underline;
  }
`;

const ButtonContainer = styled.div`
  padding-left: 35px;
`;

const PixelTracker = styled.img`
  position: fixed;
  width: 1px;
  height: 1px;
`;

const SCROLL_TOLERANCE = 5;

export const Privacy: React.FunctionComponent = observer(() => {
  const [checkedItems, setCheckedItems] = useState<{
    [id: string]: boolean;
  }>({
    privacy: false,
    terms: false,
  });
  const [hasScrolled, setHasScrolled] = useState<boolean>(false);
  const [scrollToBottmError, setScrollToBottomError] = useState<boolean>(false);
  const history = useHistory();
  const vpCookieStore = useVPCookieStore();
  const extensionStore = useExtensionStore();

  const segmentId = vpCookieStore.segmentId;
  let pixelTrackerUrl = `https://ib.adnxs.com/seg?add=${SEGMENT_AD_TARGETING_ID}`;
  if (segmentId) {
    pixelTrackerUrl += `,${segmentId}`;
  }

  const onCheckBoxClick = (key: string): void => {
    if (hasScrolled) {
      onClickAnalytics({ action: `${key}CheckBoxClick`, page: 'privacyPage' });
      const items = checkedItems;
      items[key] = !items[key];
      setCheckedItems((checkedItems) => {
        return { ...checkedItems, ...items };
      });
    } else {
      setScrollToBottomError(true);
    }
  };

  const onSubmit = (): void => {
    if (extensionStore.validationRegionCfg == undefined) {
      history.push('/fetch-error');
    } else {
      extensionStore.sendDetails();
      onClickAnalytics({ action: 'privacyAccept', page: 'privacyPage' });
      vpCookieStore.acceptPrivacy();
      history.push('/camera');
    }
  };

  const totalcheckItemCount = Object.entries(checkedItems).length;
  const checkedItemCount = Object.entries(checkedItems).filter(
    ([_key, value]) => value === true
  ).length;
  const checkBoxesStyle = {
    opacity: hasScrolled ? 1 : 0.4,
  };

  useEffect(() => {
    setDisplayPageAnalytics({ page: 'privacyPage' });

    const fetchRemoteCfg = async () => {
      await extensionStore.fetchValRegionCfg();
    };
    fetchRemoteCfg().catch(console.error);
  }, []);

  return (
    <Section freeHeight>
      <Background />
      <AdBlockerDetect />
      <PixelTracker src={pixelTrackerUrl} />
      <Content>
        <Grid container justifyContent="space-between">
          <Grid item size={40}>
            <Spacing size={60} unit={'px'} />
            <Icon height={84} width={84}>
              <Logo />
            </Icon>
            <Spacing size={80} unit={'px'} />
            <h1>What about privacy?</h1>
            <Spacing size={20} unit={'px'} />
            <p>Here are the key things you need to know:</p>
            <Spacing size={20} unit={'px'} />
            <Info
              text={
                <span>
                  <b>Our Eye-tracking technology</b> uses your webcam to predict
                  where you are looking on the screen
                </span>
              }
              icon={Blur}
            />
            <Spacing size={30} unit="px" />
            <Info
              text={
                <span>
                  <b>Images</b> are not stored. All that leaves your device are
                  the coordinates of where you were looking.
                </span>
              }
              icon={Shield}
            />
            <Spacing size={30} unit="px" />
            <Info
              text={
                <span>
                  <b>The Data We Share</b> is completely anonymous. We {"don't"}{' '}
                  collect, store or share any personal information.
                </span>
              }
              icon={Lock}
            />
          </Grid>
          <Grid item size={50}>
            <Spacing size={60} unit={'px'} />
            <PrivacyContainer
              onScroll={(event: UIEvent<HTMLDivElement>): void => {
                const element = event.target as Element;
                if (
                  Math.abs(
                    element.scrollHeight -
                      element.scrollTop -
                      element.clientHeight
                  ) <= SCROLL_TOLERANCE
                ) {
                  setHasScrolled(true);
                  setScrollToBottomError(false);
                }
              }}
            >
              <h4>Privacy Notice for “Vision Project” app users</h4>
              <br />
              Your personal data is being collected and processed by Playground
              XYZ Australia Pty. Ltd. (Australian Business Number 42 607 532
              365) (<b>we</b>, <b>us</b> or <b>our</b>). We can be contacted by
              post at Level 13, 50 Carrington St. Sydney, 2000, Australia or by
              email at{' '}
              <a href="mailto:privacy@playgroundxyz.com">
                privacy@playgroundxyz.com
              </a>
              . Our representative within the European Union is Playground XYZ
              EMEA Ltd. (UK Company No 10802474) of 2nd Floor Waverley House,
              7-12 Noel Street, London, United Kingdom, W1F 8GQ. Our
              representative within the United States is PLAYGROUND XYZ NORTH
              AMERICA CORP. (EIN 36-4922580) of 33 Irving Place New York, NY
              10003, United States.
              <br />
              <br /> If you are located in the European Union this is our direct
              collection notice to you for the purposes of the EU General Data
              Protection Regulation (<b>GDPR</b>).
              <br />
              <br /> If you are a California resident, your rights are located
              below for the purposes of the California Consumer Privacy Act (
              <b>CCPA</b>).
              <br />
              <br />
              <h5>Processing activities and lawful basis</h5> <br /> We process
              the personal data that we collect from you for the purposes and on
              the applicable lawful basis set out in the table below:
              <br />
              <br />
              <table>
                <thead>
                  <tr>
                    <th>Purpose of processing</th>
                    <th>Lawful basis</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      · To provide our services to you (or to the business that
                      employs you, if applicable)
                    </td>
                    <td>
                      For our legitimate interests in being able to provide our
                      services as requested or performance of a contract with
                      you.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      · To enable you to access and use our website or app;
                    </td>
                    <td>
                      Your consent (if given to us) and performance of a
                      contract with you.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      · To operate, protect, improve and optimise our website
                      and services business and our users’ experience, such as
                      to perform analytics, conduct research;
                    </td>
                    <td>
                      Your consent (if given to us) and to keep you informed and
                      updated on topics we understand you have an interest in.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      · To conduct eye-tracking market research and improve our
                      eye-tracking technology;
                    </td>
                    <td>
                      Your consent (if given to us) and performance of a
                      contract with you.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      · To conduct gaze inference research and improve our gaze
                      inference technology;
                    </td>
                    <td>
                      Your consent (if given to us) and performance of a
                      contract with you.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      · To administer rewards, surveys, contests, or other
                      promotional activities or events sponsored or managed by
                      us or our business partners; and
                    </td>
                    <td>
                      Your consent (if given to us) and for our legitimate
                      interests in operating and promoting our business and
                      rewarding the loyalty of our customers.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      · To comply with our legal obligations, resolve any
                      disputes that we may have with any of our users, and
                      enforce our agreements with third parties.
                    </td>
                    <td>
                      Compliance with our legal obligations and for our
                      legitimate interests in enforcing our contractual and
                      legal rights.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      · If you participate in our Vision Project Paid activity,
                      we may record the content of your screen (screen capture)
                      of your mobile device for purposes of eye-tracking market
                      research and to improve our eye-tracking technology
                    </td>
                    <td>
                      Your consent (if given to us) and performance of a
                      contract with you.
                    </td>
                  </tr>
                </tbody>
              </table>
              <br /> <br />
              We collect personal data about you in order to provide our
              services to you (or to the business that employs, if applicable)
              and so that we may contact you to obtain your instructions in
              relation to our services. If you do not provide this personal data
              to us, we may not be able to provide or perform our services to
              the same standard.
              <br />
              <br />
              <h5>Third party recipients</h5>
              <br />
              As part of processing your personal data, we may disclose personal
              information for the purposes described in this Privacy Notice to:
              <ul>
                <li>
                  our employees and authorised representatives, along with our
                  related bodies corporate in Australia, the United Kingdom and
                  the United States;{' '}
                </li>
                <li>
                  third party suppliers and service providers (including
                  providers for the operation of our websites and/or our
                  business or in connection with providing our products and
                  services to you);
                </li>
                <li>
                  our professional advisers, dealers, business partners and
                  agents;
                </li>
                <li>
                  payment systems operators (e.g. merchants receiving credit or
                  debit card payments);{' '}
                </li>
                <li>
                  our sponsors or promoters of any competition that we conduct
                  via our services;
                </li>
                <li>
                  anyone to whom our assets or businesses (or any part of them)
                  are transferred;
                </li>
                <li>
                  specific third parties authorised by you to receive
                  information held by us; and/or
                </li>
                <li>
                  other persons, including government agencies, regulatory
                  bodies and law enforcement agencies, or as required,
                  authorised or permitted by law.
                </li>
              </ul>
              <br />
              We may hold and disclose your personal data outside of the
              European Economic Area or the United States. In particular, our
              Australian companies may receive personal information that was
              originally collected by or disclosed to our UK or U.S company.
              Personal information transferred by our UK company to our
              Australian companies will be undertaken in accordance with an
              intra-group data transfer agreement that implements standard data
              protection clauses as permitted under Article 46.2 of the GDPR.
              Otherwise, our data transfers conform to industry best practices
              and local laws, if any.
              <br />
              <br />
              <h5>Overseas recipients</h5>
              <br />
              In addition, we may disclose personal information to cloud
              providers (including Google and Amazon Web Services), software
              providers (Xero) and our accountants (Hall Chadwick) and our data
              partners (BrainyBot Pty Ltd and Kantar Millward Brown) that are
              located outside of, or may store personal information outside of,
              the UK, European Union or US, including in Singapore, Australia,
              the United Kingdom and the United States. When we disclose
              personal information to these third parties, we do so on the basis
              of adequate safeguards being put in place to protect your personal
              data. These safeguards are:
              <ul>
                <li>
                  for the purposes of GDPR, an adequacy decision under Article
                  45(9) of the GDPR in respect of the EU-US Privacy Shield
                  Framework for any US-based third party who has self-certified
                  its compliance with the EU-US Privacy Shield Framework to the
                  US Department of Commerce (details of the relevant safeguard
                  can be accessed via{' '}
                  <a
                    href="http://www.privacyshield.gov/list"
                    rel="noreferrer"
                    target="_blank"
                  >
                    www.privacyshield.gov/list
                  </a>{' '}
                  by searching for the name of the recipient as listed above);
                  or
                </li>
                <li>
                  alternatively, for the purposes of GDPR, on the basis of a
                  data transfer agreement with a third party that implements
                  standard data protection clauses as permitted under Article
                  46.2 of the GDPR (details of the relevant safeguard can be
                  obtained upon request by sending an email to{' '}
                  <a href="mailto:privacy@playgroundxyz.com">
                    privacy@playgroundxyz.com
                  </a>
                  ).
                </li>
                <li>
                  otherwise, our data transfers conform to industry best
                  practices and local laws, if any and implement reasonable data
                  protection clauses (details of the relevant safeguard can be
                  obtained upon request by sending an email to{' '}
                  <a href="mailto:privacy@playgroundxyz.com">
                    privacy@playgroundxyz.com
                  </a>
                  ).
                </li>
              </ul>
              <br />
              <h5>Storage of personal data</h5>
              <br />
              We will store your personal data for up to 5 years after we have
              completed providing our services to you (or to the business that
              employs you). After this time:
              <ul>
                <li>
                  we will continue to store your personal data only to the
                  extent required by any law applicable to our business or for
                  compliance and risk management purposes; and
                </li>
                <li>
                  we will delete or de-identify your personal data when it is no
                  longer necessary or required to be kept.
                </li>
              </ul>
              <br />
              If you are located in the European Union, we do not store images
              or videos of your face for any period of time; this information is
              processed locally on your own device and is not retained by us.
              <br />
              <br />
              <h5>Your rights</h5>
              <br />
              Where we process any personal data about you on the basis of any
              consent given by you, you have the right to withdraw your consent
              at any time by giving notice to us (which you can do by sending a
              request to{' '}
              <a href="mailto:privacy@playgroundxyz.com">
                privacy@playgroundxyz.com
              </a>
              ). We will give effect to your withdrawal of consent promptly and
              will cease any processing that you no longer consent to, unless we
              have another lawful basis for that processing. The withdrawal of
              your consent will not affect the lawfulness of any processing that
              occurred prior to the date that you notified us that you were
              withdrawing your consent.
              <br />
              <br />
              If you are subject to the GDPR, <br />
              Access. You have the right to request access to a copy of the
              personal data that we hold about you and to request that we
              correct or rectify any inaccurate personal data that we hold about
              you. You also have a right to data portability, which is the right
              in certain circumstances to request a copy of your personal data
              in in a structured, commonly used and machine-readable format and
              to transmit this data to another data controller. You may also
              request that we erase any personal data that we hold about you
              which is no longer necessary for any of the purposes that we
              collected it for, which you have withdrawn your consent in respect
              of or processing which you are allowed under the GDPR to object
              to. We will comply with such requests unless we are permitted or
              required by law to retain that information. You also have the
              right to object to our processing of personal data in certain
              circumstances, including where we process personal data based on
              our legitimate interests. You can also request that we restrict
              our processing activities in some circumstances. If you make such
              a request, then we will continue to store your personal data but
              will not otherwise process your personal data without your consent
              or as otherwise permitted by law.
              <br />
              <br />
              You have a right to lodge a complaint in respect of our processing
              of your personal data with the data protection supervisory
              authority in the member state of the European Union that you
              ordinarily reside or work in.
              <br />
              <br />
              If you are a California resident, your rights are as follows:
              <br />
              <br />
              Access. If you are subject to the CCPA, you have the right to
              request access to a copy of the personal data that we hold about
              you no more than twice in any 12 month period. If you wish to
              receive a copy of that personal data, you should send your request
              for such a copy to{' '}
              <a href="mailto:privacy@playgroundxyz.com">
                privacy@playgroundxyz.com
              </a>
              , and include the name of the account {"holder's"} designated
              representative. If you do not provide complete and accurate
              information, we reserve the right to reject your request until you
              provide all the data we need to verify your request. If you
              request copies of the personal data we collect about you more than
              twice in any 12 month period, we will decline to provide it to
              you. If you contend we have not complied in full with your request
              for data or we elect to reject your request, you have the right to
              appeal our decision by contacting us as{' '}
              <a href="mailto:privacy@playgroundxyz.com">
                privacy@playgroundxyz.com
              </a>{' '}
              and include in the Re: line of your email - APPEAL - and include
              the grounds for your appeal.
              <br />
              <br />
              Data Portability: You have a right to data portability, meaning we
              will provide you with a copy of the personal data we maintain
              about you free of charge in a useable and portable manner, If you
              have an account with us, we will provide you the requested data
              through your account. If you do not have an account with us, you
              may request the data by contacting us at{' '}
              <a href="mailto:privacy@playgroundxyz.com">
                privacy@playgroundxyz.com
              </a>
              . Whether or not you have an account with us, you may also request
              the data by contacting us at{' '}
              <a href="mailto:privacy@playgroundxyz.com">
                privacy@playgroundxyz.com
              </a>{' '}
              or on our Toll Free Number: 1-833-243-0509.
              <br />
              <br />
              Deletion: You may also request that we delete any personal data we
              hold about you which is no longer necessary for any of the
              purposes for which we collected it. We will comply with such
              requests unless we are permitted or required by law or for
              legitimate business reasons (such as system integrity) to retain
              that information. You also have the right to object to our
              processing of personal data in certain circumstances, including
              where we process personal data based on our legitimate interests.
              You can also request that we restrict our processing activities in
              some circumstances. If you make such a request, in those
              circumstances, then we will continue to store your personal data
              but will not otherwise process your personal data without your
              consent or as otherwise permitted by law.
              <br />
              <br />
              Request Verification: If we receive requests for copies of
              personal information, or other requests pursuant to a{' '}
              {"consumer's "}
              rights under the CCPA , to protect your privacy interests, we may
              require that additional data be submitted to confirm the person
              making the request is the authorized representative of the
              individual whose data we hold. The specifics we may request will
              be determined by the contents of the request we received.
              <br />
              <br />
              Please note that we do not sell the personal data of our
              customers.
              <br />
              <br />
              Non-Discrimination: You are free to exercise any and all rights
              granted by the CCPA. Doing so will not have an impact on our
              relationship with you. We will not discriminate against you for
              having done so.
              <br />
              <br />
              For more information on our processing of your personal data,
              please see our Privacy Policy, which is available at &nbsp;
              <a href="/privacy-policy" rel="noreferrer" target="_blank">
                desktop.vision-project.co/privacy-policy
              </a>
            </PrivacyContainer>
            {scrollToBottmError ? (
              <React.Fragment>
                <Spacing size={1} unit={'vh'} />
                <div>
                  Please read through our Privacy Notice above to continue
                </div>
              </React.Fragment>
            ) : null}
            <Spacing size={30} unit={'px'} />
            <div style={checkBoxesStyle}>
              <Grid container justifyContent="flex-start" alignItems="center">
                <Grid item size={15}>
                  <Checkbox
                    ticked={checkedItems['privacy']}
                    itemKey="privacy"
                    clickHandler={onCheckBoxClick}
                  >
                    <Tick />
                  </Checkbox>
                </Grid>
                <Grid item size={85}>
                  <span>
                    I have read and agree to the{' '}
                    <a
                      href={getPathFromRouteId('privacy-policy')}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </span>
                </Grid>
              </Grid>
            </div>
            <Spacing size={20} unit={'px'} />
            <div style={checkBoxesStyle}>
              <Grid container justifyContent="flex-start" alignItems="center">
                <Grid item size={15}>
                  <Checkbox
                    ticked={checkedItems['terms']}
                    itemKey="terms"
                    clickHandler={onCheckBoxClick}
                  >
                    <Tick />
                  </Checkbox>
                </Grid>
                <Grid item size={85}>
                  <span>
                    I agree to the{' '}
                    <a
                      href={getPathFromRouteId('terms-of-use-agreement')}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of Use Agreement
                    </a>{' '}
                    and{' '}
                    <a
                      href={getPathFromRouteId('privacy-notice')}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Notice
                    </a>
                  </span>
                </Grid>
              </Grid>
            </div>
            <Spacing size={40} unit="px" />
            <ButtonContainer>
              <Button
                label="I accept"
                disabled={checkedItemCount != totalcheckItemCount}
                clickHandler={onSubmit}
              />
            </ButtonContainer>
          </Grid>
        </Grid>
        <Spacing size={50} unit="px" />
      </Content>
    </Section>
  );
});
