import React from 'react';
import { Background } from '../../../../shared/components/background';
import { Section } from '../../../../shared/components/section';
import { Content } from '../../../../shared/components/content';
import { Waves } from '../../composites/waves/waves';
import { Spacing } from '../../../../shared/components/spacing';
import { useEffect } from 'react';
import { getPathFromRouteId } from '../../core/routes';
import { completeStudy } from './cintRequest';
import { useHistory } from 'react-router';
import { useVPCookieStore, useExtensionStore } from '../../stores/store';

export const Loading: React.FunctionComponent = () => {
  const vpCookieStore = useVPCookieStore();
  const vpExtensionStore = useExtensionStore();
  const userId = vpCookieStore.userId;
  const feedId = vpCookieStore.feedId;
  const platformId = vpCookieStore.platformId;
  const history = useHistory();

  const cintCompletion = async (): Promise<boolean> => {
    const response = await completeStudy(userId, feedId);
    if (response.success && response.redirectUrl) {
      vpExtensionStore.openNewTab(response.redirectUrl);
      return true;
    }
    return false;
  };

  // redirect user to completion page or error page
  const redirect = async (): Promise<void> => {
    let newUrl = getPathFromRouteId('completion');
    if (platformId === 'cint') {
      const response = await cintCompletion();
      if (!response) {
        newUrl = getPathFromRouteId('error');
      }
    }
    history.push(newUrl);
  };

  useEffect(() => {
    redirect();
  }, []);

  return (
    <Section>
      <Background />
      <Waves side="both" />
      <Content>
        <div className="centered">
          <Spacing size={40} unit={'px'} />
          {/* <ContentStyled> */}
          <Spacing size={20} unit={'px'} />
          <h4>Fetching response from the test provider</h4>
          <Spacing size={20} unit={'px'} />
          <div>Please wait...</div>
        </div>
      </Content>
    </Section>
  );
};
