import styled from '@emotion/styled';
import * as React from 'react';

import Logo from '../assets/images/logo.svg';
import { Icon } from '../components/icon';
import { Spacing } from '../components/spacing';

const ProjectStyled = styled.div`
  display: inline-block;
  font-family: 'Avenir', helvetica, arial, sans-serif;
  font-size: 1.2em;
  letter-spacing: 4px;
  font-weight: 300;
  text-transform: uppercase;
`;

interface HeaderSmallProps {
  label?: string;
}

export const HeaderSmall: React.FunctionComponent<HeaderSmallProps> = (
  props: HeaderSmallProps
) => {
  const { label } = props;
  return (
    <React.Fragment>
      <div className="centered">
        <Icon height={56} width={56}>
          <Logo />
        </Icon>
      </div>
      <Spacing size={20} unit={'px'} />
      <div className="centered">
        <ProjectStyled>{label}</ProjectStyled>
      </div>
    </React.Fragment>
  );
};
