/* eslint-disable */
import { action, computed, observable } from 'mobx';
import {
  setErrorAnalytics,
  setUserPropertiesAnalytics,
} from '../services/analyticService';
import Cookies from 'universal-cookie';
import { Logger } from '../../../shared/logger';
import { VP_API_URL, PLATFORMS } from '../config';
import { getDurationParam, headRequest } from '../core/utils';
import { setValUserProperty } from '../services/firebaseService';

const COOKIE_NAME = 'vision-study';

export class VisionProjectCookieStore {
  @observable
  private _authorised: boolean | undefined = undefined;

  @observable
  private _feedId = '';

  @observable
  private _feedUrlPath = '';

  @observable
  private _adCampaignId = '';

  @observable
  private _segmentId = '';

  @observable
  private _feedType = '';

  @observable
  private _type = '';

  @observable
  private _duration = 0;

  @observable
  private _privacyAccepted = false;

  @observable
  private _userId = '';

  @observable
  private _surveyId = '';

  @observable
  private _platformId = '';

  // make this a string in case we want to start enabling different types of debugging.
  // now it gets converted to a boolean vp-lib (we have only the debug tools for youtube dekstop)
  @observable
  private _vpDebugMode = '';

  @observable
  private _vpQaMode = '';

  @observable
  private _validationRegionVersion = '';

  @computed
  get authorised(): boolean | undefined {
    return this._authorised;
  }

  @computed
  get feedId(): string {
    return this._feedId;
  }

  @computed
  get feedUrlPath(): string {
    return this._feedUrlPath;
  }

  @computed
  get adCampaignId(): string {
    return this._adCampaignId;
  }

  @computed
  get segmentId(): string {
    return this._segmentId;
  }

  @computed
  get feedType(): string {
    return this._feedType;
  }

  @computed
  get type(): string {
    return this._type;
  }

  @computed
  get duration(): number {
    return this._duration;
  }

  @computed
  get privacyAccepted(): boolean {
    return this._privacyAccepted;
  }

  @computed
  get userId(): string {
    return this._userId;
  }

  @computed
  get surveyId(): string {
    return this._surveyId;
  }

  @computed
  get vpDebugMode(): string {
    return this._vpDebugMode;
  }
  @computed
  get vpQaMode(): string {
    return this._vpQaMode;
  }
  @computed
  get platformId(): string {
    return this._platformId;
  }
  @computed
  get validationRegionVersion(): string {
    return this._validationRegionVersion;
  }

  @action
  acceptPrivacy(): void {
    const cookies = new Cookies(document.cookie);
    this._privacyAccepted = true;
    const vpCookie = cookies.get(COOKIE_NAME);
    if (vpCookie) {
      cookies.set(
        COOKIE_NAME,
        {
          ...vpCookie,
          privacyAccepted: this._privacyAccepted,
        },
        {
          httpOnly: false,
          sameSite: 'strict',
        }
      );
    }
  }

  @action
  async setAuthorised(): Promise<void> {
    const cookies = new Cookies(document.cookie);
    const params = new URLSearchParams(window.location.search);

    // mturk prime appends a 'workerId' param instead of user_id
    const userId =
      params.get('user_id') || params.get('workerId') || params.get('ext');
    // TODO need to validate campaignId is valid
    const adCampaignId = params.get('ad_camp_id') || '';

    let feedUrlPath = '';
    let feedId = '';
    if (params.has('f_id')) {
      feedId = params.get('f_id') || '';
      feedUrlPath = 'customFeed';
    } else if (params.has('pxyz_camp_id')) {
      feedId = params.get('pxyz_camp_id') || '';
      feedUrlPath = 'feed';
    }

    if (
      !params.has('id') ||
      !params.has('d') ||
      !feedId ||
      !feedUrlPath ||
      !params.has('s_id') ||
      !userId
    ) {
      this._authorised = false;
      return;
    }
    //check feed exists without loading it
    let feedExists = false;
    if (feedId && feedUrlPath) {
      this._feedId = feedId;
      this._feedUrlPath = feedUrlPath;
      try {
        feedExists = await headRequest(
          `${VP_API_URL}/siteDirectory/${feedUrlPath}/` + this._feedId
        );
      } catch (e) {
        const message = `feed could not be found \n   feed : ${this._feedId}`;
        setErrorAnalytics({
          error: 'authorisation',
          message: message,
          page: 'vpCookie',
        });
        Logger.warn(message);
        feedExists = false;
      }
    }
    const platformId = params.get('id') || '';
    //check platform is supported
    const platformExists = PLATFORMS.includes(platformId);
    //check duration
    let durationValid = false;
    //check duration format is valid
    durationValid = /\d{3}-\d{2,3}/gm.test(params.get('d') || '');
    if (durationValid) {
      const durationValue = getDurationParam(params.get('d') || '');
      durationValid = durationValue == 1 || durationValue % 5 == 0;
      this._duration = durationValue * 60 * 1000;
      this._type = params.get('d') || '';
    }

    const vpDebugMode = params.get('vp_debug_mode') || '';
    const vpQaMode = params.get('vp_qa_mode') || '';

    this._surveyId = params.get('s_id') || '';
    this._feedType = params.get('f_type') || '';
    this._segmentId = params.get('seg_id') || '';
    this._vpDebugMode =
      process.env.DEPLOY_ENVIRONMENT === 'production' ? '' : vpDebugMode;

    this._vpQaMode =
      process.env.DEPLOY_ENVIRONMENT === 'production' ? '' : vpQaMode;

    this._adCampaignId = adCampaignId;

    // default validation region version is 1
    this._validationRegionVersion = params.get('val') || '1';

    setValUserProperty(this._validationRegionVersion);

    if (feedExists && platformExists && durationValid) {
      Logger.info('authorisation parameters valid - creating cookies');
      setUserPropertiesAnalytics({
        userId: userId,
        feedId: feedId,
        platformId: platformId,
        adCampaignId: adCampaignId,
        surveyId: this._surveyId,
      });
      cookies.set(
        COOKIE_NAME,
        {
          duration: this._duration,
          feedId: this._feedId,
          feedUrlPath: this._feedUrlPath,
          adCampaignId: this._adCampaignId,
          type: this._type,
          userId,
          platformId,
          privacyAccepted: false,
          surveyId: this._surveyId,
          feedType: this._feedType,
          segmentId: this._segmentId,
          vpDebugMode: this._vpDebugMode,
          vpQaMode: this._vpQaMode,
          validationRegionVersion: this._validationRegionVersion,
        },
        {
          httpOnly: false,
          sameSite: 'strict',
        }
      );
      Logger.info(
        `session values
         duration : ${this._duration}
         feed : ${this._feedId}
         feed type : ${this._feedType}
         feed API Path: ${this._feedUrlPath}
         adCampaignId : ${this._adCampaignId}
         type : ${this._type}
         userid : ${userId}
         surveyMonkeyId : ${this._surveyId}
         segmentId: ${this._segmentId}
         platformId:${platformId}
         vpDebugMode: ${this._vpDebugMode}
         vpQaMode: ${this._vpQaMode}`
      );
    } else {
      const message = `authorisation failed - parameters invalid \n feed:${feedExists} \n platform:${platformExists} \n duration:${durationValid} \n userid:${userId}`;
      setErrorAnalytics({
        error: 'authorisation',
        message: message,
        page: 'vpCookie',
      });
      Logger.warn(message);
    }
    this._authorised = feedExists && platformExists && durationValid;
  }

  @action
  loadAuthorisation(): void {
    const cookies = new Cookies(document.cookie);
    if (cookies.get(COOKIE_NAME)) {
      Logger.info('cookie found - loading session values');
      this._duration = cookies.get(COOKIE_NAME)['duration'];
      this._feedId = cookies.get(COOKIE_NAME)['feedId'];
      this._segmentId = cookies.get(COOKIE_NAME)['segmentId'];
      this._type = cookies.get(COOKIE_NAME)['type'];
      this._privacyAccepted = cookies.get(COOKIE_NAME)['privacyAccepted'];
      this._userId = cookies.get(COOKIE_NAME)['userId'];
      this._surveyId = cookies.get(COOKIE_NAME)['surveyId'];
      this._feedType = cookies.get(COOKIE_NAME)['feedType'];
      this._feedUrlPath = cookies.get(COOKIE_NAME)['feedUrlPath'];
      this._adCampaignId = cookies.get(COOKIE_NAME)['adCampaignId'];
      this._vpDebugMode = cookies.get(COOKIE_NAME)['vpDebugMode'];
      this._vpQaMode = cookies.get(COOKIE_NAME)['vpQaMode'];
      this._platformId = cookies.get(COOKIE_NAME)['platformId'];
      this._validationRegionVersion = cookies.get(COOKIE_NAME)[
        'validationRegionVersion'
      ];
      Logger.info(
        `session values \n
         duration : ${this._duration}
         feed : ${this._feedId}
         feed type : ${this._feedType}
         feed API path : ${this._feedUrlPath}
         adCampaignId: ${this._adCampaignId}
         type : ${this._type}
         userid : ${this._userId}
         surveyMonkeyId : ${this._surveyId}
         segmentId: ${this._segmentId}
         platformId:${this._platformId}
         vpDebugMode: ${this._vpDebugMode}
         vpQaMode: ${this._vpQaMode}`
      );
      this._authorised = true;

      setUserPropertiesAnalytics({
        userId: this._userId,
        feedId: this._feedId,
        platformId: this._platformId,
        adCampaignId: this._adCampaignId,
        surveyId: this._surveyId,
      });
      return;
    }
  }
}
