// this file contains the content that is configured based on the feed type
import React from 'react';
import { SiteDirectory as SiteDirectoryContent } from '../controllers/siteDirectoy/siteDirectory';

interface SiteDirectoryContent {
  headerTitle: string;
  modalPopup: {
    boldText: string;
    spanText: string;
  };
}

// so far item1 in the grid don't require custom config
interface TaskSummaryContent {
  bulletTitle: string;
  gridItems: {
    item0: JSX.Element;
    item2: JSX.Element;
  };
}

interface FeedTypeContent {
  siteDirectoryContent: SiteDirectoryContent;
  taskSummaryContent: TaskSummaryContent;
}

// if we add a new custom config we need to add it here.
const config: Record<'g' | 'default', FeedTypeContent> = {
  g: {
    siteDirectoryContent: {
      headerTitle: 'GAMES DIRECTORY',
      modalPopup: {
        boldText: 'We will open this game in a new tab.',
        spanText:
          'Keep your head in the same position while playing to ensure the eye tracking stays accurate',
      },
    },
    taskSummaryContent: {
      bulletTitle:
        'Play any of the games below, we will alert you when the task is complete.',
      gridItems: {
        item0: (
          <span>
            <b>Browse and Play Naturally.</b> We know it’s hard when we are
            ‘watching’. But seriously, we won’t judge your gaming skills.
          </span>
        ),
        item2: (
          <span>
            <b>Stick to the Games Directory.</b> You can play as many games as
            you like, but the study will stop if you go to a site that’s not on
            the list.
          </span>
        ),
      },
    },
  },
  default: {
    siteDirectoryContent: {
      headerTitle: 'SITE DIRECTORY',
      modalPopup: {
        boldText: 'We will open this website in a new tab.',
        spanText:
          'Keep your head in the same position while browsing to ensure the eye tracking stays accurate',
      },
    },
    taskSummaryContent: {
      bulletTitle:
        'Browse any of the sites below, we will alert you when the task is complete.',
      gridItems: {
        item0: (
          <span>
            <b>Browse Naturally.</b> We know it’s hard when we are ‘watching’.
            But seriously, you can click any article, we won’t judge you.
          </span>
        ),
        item2: (
          <span>
            <b>Stick to the Site Directory.</b> You can browse multiple sites
            but the study will stop if you visit a site that’s not on the list.
            Don’t do it!
          </span>
        ),
      },
    },
  },
};

export const feedTypeContent = (feedType: string): FeedTypeContent => {
  switch (feedType) {
    case 'g':
      return config.g;
    default:
      return config.default;
  }
};
