import { css, Global } from '@emotion/core';
import React from 'react';

import avenir from '../assets/fonts/Avenir.woff';

export const GlobalStyles: React.FunctionComponent = () => {
  return (
    <Global
      styles={css`
        @font-face {
          src: "url('${avenir}') format(woff)";
          font-family: 'Avenir';
        }
        * {
          font-family: 'Roboto', helvetica, arial, sans-serif;
          color: #fff;
          margin: 0;
        }
        body {
          background-image: linear-gradient(
            #2f1c3b 0%,
            #242424 25%,
            #2f1c3b 50%,
            #242424 75%
          );
          background-size: 100% 100%;
          background-repeat: no-repeat;
          font-size: 100%;
          background: #242424;
        }
        .centered {
          text-align: center;
        }
        .right {
          text-align: right;
        }
        h1 {
          font-weight: bold;
          font-size: 2.8em;
          line-height: 1.48em;
          letter-spacing: 0.03em;
          margin: 0;
        }
        h2 {
          line-height: 1.9em;
          margin: 0;
          font-size: 1.6em;
          font-weight: 300;
        }
        h3 {
          font-weight: bold;
          font-size: 2em;
          line-height: 1.2em;
          margin: 0;
        }
        h4 {
          line-height: 1.9em;
          margin: 0;
          font-size: 1.6em;
        }
        h5 {
          font-size: 1.3em;
          font-weight: normal;
          margin: 0;
        }
        h6 {
          font-size: 1.2em;
          letter-spacing: 1px;
          line-height: 1.8em;
          font-weight: 600;
        }
        span {
          font-size: 1em;
          line-height: 1.6em;
          font-weight: 300;
          letter-spacing: 0.04em;
        }
        p {
          line-height: 1.9em;
          margin: 0;
          font-size: 1.2em;
          font-weight: 300;
        }
        a {
          color: #fff;
          text-decoration: underline;
          cursor: pointer;
          &:hover {
            color: #acacac;
          }
        }
        .route-enter {
          opacity: 0;
        }
        .route-active {
          opacity: 1;
          transition: opacity 200ms;
        }
        .route-exit {
          opacity: 1;
        }
        .route-exit-active {
          opacity: 0;
          transition: opacity 200ms;
        }
        .Toaster__manager-top-right {
          right: 20px !important;
        }
        .Toaster__alert {
          max-width: 330px;
        }
        .Toaster__alert_close {
          top: 50%;
          transform: translateY(-50%);
          span {
            color: #000;
          }
        }
      `}
    />
  );
};
