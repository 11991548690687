import React from 'react';
import { getPathFromRouteId } from '../../core/routes';
import { ErrorTemplate } from '../../composites/error/errorTemplate';
import { useHistory } from 'react-router';

export const GenericError: React.FunctionComponent = () => {
  const history = useHistory();

  const onSubmit = (): void => {
    const completionUrl = getPathFromRouteId(`completion`);
    history.push(completionUrl);
  };

  return (
    <ErrorTemplate
      headerSmall={'NO RESPONSE'}
      title={'Sorry, something went wrong'}
      content={`We could not get the response from the test provider. 
      A support ticket has been created automatically and you will be contacted as soon as possible. 
      For further assistance please email support@vision-project.co`}
      button={{
        label: 'Finish Study',
        disabled: false,
        clickHandler: onSubmit,
      }}
    />
  );
};
